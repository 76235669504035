import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user")); // Retrieve the user
  let location = useLocation();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const isAdminRoute = location.pathname.startsWith("/dashboard");
 

  if (user.data && !isAdminRoute) {
    return <Navigate to="/dashboard" replace />;
  }
  
  return children;
};
export default ProtectedRoute;
