import React, { useEffect, useRef, useState } from 'react';
import khiladilogo from '../assets/khiladilogo.png';
import { Link, useNavigate } from 'react-router-dom';
import comingSoonGif from '../assets/coming-soon.gif';
import axios from 'axios';
import homeIcon from '../assets/home-icons.png'

export default function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [vendorCat, setVendorCat] = useState([]);
  const [user, setUser] = useState(null);
  const searchBarRef = useRef(null);

  const navigate = useNavigate(); // Hook to navigate

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    axios
      .get('/api/get-all-category')
      .then((response) => {
        setVendorCat(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchInput(value);
    setShowSuggestions(e.target.value.trim() !== "");
    // setShowSuggestions(true); // Show suggestions when typing

    // Filter categories based on input
    const filtered = vendorCat.filter((category) =>
      category.category.toLowerCase().includes(value)
    );
    setFilteredSuggestions(filtered);
  };

  const handleSelectSuggestion = (suggestion) => {
    setSearchInput(suggestion.category);
    setShowSuggestions(false);
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const openJobModal = () => {
    setShowJobModal(true);
  };

  const openInvestModal = () => {
    setShowInvestModal(true);
  };

  const closeModal = () => {
    setShowJobModal(false);
    setShowInvestModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  const handleSearch = () => {
    const selectedCategory = filteredSuggestions.find(
      (suggestion) => suggestion.category.toLowerCase() === searchInput.toLowerCase()
    );

    if (selectedCategory) {
      const formattedCategory = selectedCategory.category.replace(/\s+/g, "-");
      navigate(`/${formattedCategory}`);
    } else {
      alert("Category not found.");
    }

    setShowSuggestions(false); // Hide suggestions after search
    setSearchInput(""); // Clear the input field
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setShowSuggestions(false); // Hide suggestions
      // setSearchInput(""); // Clear the input field
      // window.location.reload();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light" style={{ height: '100px' }}>
        <div className="container-fluid navbarContainer d-flex justify-content-between">
          <div className="d-flex align-items-center position-relative">
            <div className="logo-text navbarkhiladi-logo">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <img
                  style={{
                    verticalAlign: 'baseline',
                    width: '250px',
                    height: '60px',
                  }}
                  src={khiladilogo}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="d-flex ms-4 mt-2 position-relative w-100 navSearchbar w-100" ref={searchBarRef}>
              <input
                type="text"
                className="rounded-pill search-bar"
                placeholder="Search Category.."
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <button
                className="btn btn-primary ms-2 mt-0"
                onClick={handleSearch}
                style={{ borderRadius: '50px' }}
              >
                Search
              </button>
              {showSuggestions && filteredSuggestions.length > 0 && (
                <ul className="suggestions-list">
                  {filteredSuggestions.map((suggestion) => (
                    <li
                      key={suggestion.id}
                      onMouseDown={() => handleSelectSuggestion(suggestion)}
                      className="cursor-pointer"
                    >
                      {suggestion.category}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="homenavbar-icon mt-2 ms-4">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img
                style={{
                  verticalAlign: 'baseline',
                  width: '64px',
                  height: '50px',
                }}
                src={homeIcon}
                alt="Logo"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler navbarToggleButton"
            type="button"
            onClick={handleNavCollapse}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`mt-3 collapse bg-light navbar-collapse ${isNavCollapsed ? 'collapse' : 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
            <li className="nav-item active mb-2 w-100">
                <Link to="/post-Job" className=" justify-center text-center nav-link registerNow">
                  Post job
                </Link>
              </li>
              <li className="nav-item active mb-2 w-100">
                <Link to="/JobsListing" className=" justify-center text-center nav-link registerNow">
                  Jobs
                </Link>
              </li>
              <li className="nav-item active mb-2 w-100">
                <button className="nav-link registerNow" onClick={openInvestModal}>
                  Invest With Us
                </button>
              </li>
              <li className="nav-item active mb-2 w-100">
                {!user ? (
                  <Link to={`/login`} className="justify-center text-center nav-link loginNow">
                    Login
                  </Link>
                ) : (
                  <button onClick={handleLogout} className="justify-center text-center nav-link loginNow">
                    Logout
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Modals */}
      {showJobModal && (
        <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form>
                  <img src={comingSoonGif} alt="Coming Soon" />
                  <h5>Job Opportunities - Coming Soon!</h5>
                  <p>We are working on exciting opportunities. Stay tuned!</p>
                </form>
              </div>
              <div className="modal-footer">
                <button className="noselect me-4 close-btn" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showInvestModal && (
        <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form>
                  <img src={comingSoonGif} alt="Coming Soon" />
                  <h5>Invest With Us - Coming Soon!</h5>
                  <p>We're preparing awesome investment opportunities. Check back soon!</p>
                </form>
              </div>
              <div className="modal-footer">
                <button className="noselect me-4 close-btn" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
