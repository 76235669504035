import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import axios from "axios";
import { useParams } from "react-router-dom";
import DUMMYCITIES from '../../../components/dummydata.json'
import { Form } from 'react-bootstrap';

const AddUser = () => {
  const [vendorCat, setVendorCat] = useState([]);
  const [selectedState, setSelectedState] = useState('Select State');
  const [districts, setDistricts] = useState([]);
  const [selectedDist, setSelectedDist] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [error, setError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const maxWords = 500;
  const { id } = useParams();
  const [formData, setFormData] = useState({
    state: "",
    city: "",
    category: "",
    businessUrl: "",
    bannerImage: "",
    profileUrl: "",
    phone: '',
    email: "",
    password: "",
    confirmPassword: "",
    address: '',
    isPaid: '',
    title: '',
    mapLocation: '',
    about: '',
    askForPrice: '',
    heading1: '', heading2: '', heading3: '', heading4: '', heading5: '', heading6: '', heading7: '', heading8: '', heading9: '', heading10: '', heading11: '', heading12: '',
    catelogue1: '', catelogue2: '', catelogue3: '', catelogue4: '', catelogue5: '', catelogue6: '', catelogue7: '', catelogue8: '', catelogue9: '', catelogue10: '', catelogue11: '', catelogue12: '',
    yearsExperience: '',
    ratings: '',
    startDate: '',
    expireDate: '',
    timings: '',
    activation: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const words = value.trim().split(/\s+/).filter(word => word !== "");
    if (words.length <= maxWords) {
      setFormData({ ...formData, [name]: value });
      setWordCount(words.length);
    } else {
      alert("You can enter max 500 words");
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === "catelogue") {
      // Handle multiple files
      setFormData((prev) => ({
        ...prev,
        [name]: Array.from(files),
      }));
    } else {
      // Handle single file
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    }
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      state: state,  // Update formData with the selected state
      city: "", // Reset city when state changes
    }));

    if (state && DUMMYCITIES[state]) {
      setDistricts(DUMMYCITIES[state].sort() || []);  // Set districts for selected state
    } else {
      setDistricts([]);  // Clear districts if state is invalid or not selected
    }
  };

  const handleDistrictChange = (e) => {
    const district = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      city: district,  // Update formData with the selected city
    }));
  };

  const fetchCategories = () => {
    axios.get('/api/get-all-category')
      .then((response) => {
        setVendorCat(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  useEffect(() => {
    const foundUser = vendors.find((vendor) => vendor._id === id);
    if (foundUser) {
      setEditUser(foundUser);
      setCategoryName(foundUser.category);
    }
  }, [id, vendors]);

  const submitHandler = async (event) => {
    event.preventDefault();

    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "catelogue") {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((file) => {
            data.append(key, file); // Append each file individually
          });
        }
      } else if (key !== "bannerImage" && key !== "profileUrl") {
        data.append(key, formData[key]); // Append other fields
      }
    });

    if (formData.bannerImage) {
      data.append("bannerImage", formData.bannerImage);
    }
    if (formData.profileUrl) {
      data.append("profileUrl", formData.profileUrl);
    }



    try {
      const response = await axios.post("/api/add_vender", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Response:", response.data);
      Swal.fire("Success!", "Vendor added successfully.", "success");
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error!", "Failed to upload vendor.", "error");
    }
  };

  useEffect(() => {
    console.log('Selected State:', formData.state);
    console.log('Districts:', districts);
  }, [formData.state, districts]);



  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar d-flex">
          <h4>Add New User</h4>
          {responseMessage && <p>{responseMessage}</p>}
        </div>

        <form action="/upload" method="POST" enctype="multipart/form-data" onSubmit={submitHandler}>
          <div className="row">

            {/* Left Column */}

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="isPaid" className="form-label">
                  Paid or Free
                </label>
                <select
                  className="form-control"
                  id="isPaid"
                  value={formData.isPaid}
                  onChange={handleChange}
                  name="isPaid"
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="activation" className="form-label">
                  Activate Vendor
                </label>
                <select
                  className="form-control"
                  id="activation"
                  value={formData.activation}
                  onChange={handleChange}
                  name="activation"
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Business Name(must be unique)
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="title"
                  value={formData.title}
                  required
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const wordCount = inputValue.trim().split(/\s+/).length;

                    if (wordCount <= 15) {
                      handleChange(e); // Update state if within limit
                    } else {
                      alert("The title can have a maximum of 15 words.");
                    }
                  }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="bannerImage" className="form-label">
                  Upload Banner Image (height:250px ,width:1300px)
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileChange}
                  id="bannerImage"
                  accept="image/*" // Restrict to images
                  name="bannerImage"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="profileUrl" className="form-label">
                  Upload Profile Image
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                  id="profileUrl"
                  name="profileUrl"
                  accept="image/*"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  name="phone"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="categoryName" className="form-label">
                  Category Name
                </label>
                <select
                  className="form-control"
                  id="categoryName"
                  value={formData.category}
                  onChange={handleChange}
                  name="category"
                  required
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {vendorCat.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="timings" className="form-label">
                  Timing (Timing format must be like- 10AM to 7PM)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="timings"
                  value={formData.timings}
                  onChange={handleChange}
                  name="timings"

                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="state" className="form-label">State</label>
                <Form.Select
                  className="selectStaCitCat form-control py-2"
                  id="state"
                  value={formData.state}
                  onChange={handleStateChange}  // Updates formData.state
                  name="state"
                >
                  <option value="">Select state</option>
                  {Object.keys(DUMMYCITIES).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="form-group mb-2">
                <label htmlFor="city" className="form-label">City</label>
                {formData.state && (
                  <Form.Select
                    className="selectStaCitCat form-control py-2"
                    id="city"
                    value={formData.city}
                    onChange={handleDistrictChange}  // Updates formData.city
                    name="city"
                  >
                    <option value="">Select city</option>
                    {districts.length > 0 ? (
                      districts.map((district, idx) => (
                        <option key={idx} value={district}>
                          {district}
                        </option>
                      ))
                    ) : (
                      <option value="">No cities available</option>
                    )}
                  </Form.Select>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="about" className="form-label">
                  About (Maximum {maxWords} words) <small className="ms-3 form-text text-muted">
                    {wordCount} / {maxWords} words
                  </small>
                </label>
                <textarea
                  className="form-control"
                  id="about"
                  rows="5"
                  value={formData.about}
                  onChange={handleChange}
                  name="about"
                />

              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  className="form-control"
                  id="address"
                  rows="3"
                  cols="5"
                  value={formData.address}
                  onChange={handleChange}
                  name="address"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="yearsExperience" className="form-label">
                  Years of Experience
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="yearsExperience"
                  value={formData.yearsExperience}
                  onChange={handleChange}
                  name="yearsExperience"

                />
              </div>
              <div className="mb-3">
                <label className="form-label">Register Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Expire Date</label>
                <input
                  type="date"
                  name="expireDate"
                  value={formData.expireDate}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

            </div>

            {/* Middle Column */}

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="mapLocation" className="form-label">
                  Map Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mapLocation"
                  value={formData.mapLocation}
                  onChange={handleChange}
                  name="mapLocation"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Youtube Video Link (embed)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="businessUrl"
                  value={formData.businessUrl}
                  onChange={handleChange}
                  name="businessUrl"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  name="confirmPassword"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images
                </label>
                <input
                  type="file"
                  name="catelogue1"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading1"
                  value={formData.heading1}
                  onChange={handleChange}
                  name="heading1"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 2
                </label>
                <input
                  type="file"
                  name="catelogue2"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading2"
                  value={formData.heading2}
                  onChange={handleChange}
                  name="heading2"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 3
                </label>
                <input
                  type="file"
                  name="catelogue3"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 3
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading3"
                  value={formData.heading3}
                  onChange={handleChange}
                  name="heading3"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 4
                </label>
                <input
                  type="file"
                  name="catelogue4"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 4
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading4"
                  value={formData.heading4}
                  onChange={handleChange}
                  name="heading4"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 5
                </label>
                <input
                  type="file"
                  name="catelogue5"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 5
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading5"
                  value={formData.heading5}
                  onChange={handleChange}
                  name="heading5"

                />
              </div>


            </div>
            {/* Right Side */}
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 6
                </label>
                <input
                  type="file"
                  name="catelogue6"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 6
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading6"
                  value={formData.heading6}
                  onChange={handleChange}
                  name="heading6"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 7
                </label>
                <input
                  type="file"
                  name="catelogue7"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 7
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading7"
                  value={formData.heading7}
                  onChange={handleChange}
                  name="heading7"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 8
                </label>
                <input
                  type="file"
                  name="catelogue8"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*" aw3
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 8
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading8"
                  value={formData.heading8}
                  onChange={handleChange}
                  name="heading8"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 9
                </label>
                <input
                  type="file"
                  name="catelogue9"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 9
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading9"
                  value={formData.heading9}
                  onChange={handleChange}
                  name="heading9"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 10
                </label>
                <input
                  type="file"
                  name="catelogue10"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 10
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading10"
                  value={formData.heading10}
                  onChange={handleChange}
                  name="heading10"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 11
                </label>
                <input
                  type="file"
                  name="catelogue11"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 11
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading11"
                  value={formData.heading11}
                  onChange={handleChange}
                  name="heading11"

                />
              </div>
              <div className="mb-3">
                <label htmlFor="catalogueImages" className="form-label">
                  Upload Catalogue Images 12
                </label>
                <input
                  type="file"
                  name="catelogue12"
                  onChange={handleFileChange}
                  className="form-control"
                  id="catalogueImages"
                  multiple
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">
                  Catelogue Heading 12
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading12"
                  value={formData.heading12}
                  onChange={handleChange}
                  name="heading12"

                />
              </div>

            </div>
            {/* onClick={handleUpload} */}
            <button type="submit" className="btn btn-success">
              Add User
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddUser;

