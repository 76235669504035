import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./SliderBanner.css";
import khiladilogo from '../assets/khiladilogo.png'
import myphoto1 from '../assets/khiladilogo.png';
import myphoto2 from '../assets/khiladilogo.png';
import myphoto3 from '../assets/khiladilogo.png';
import myphoto4 from '../assets/khiladilogo.png';
import myphoto5 from '../assets/khiladilogo.png';
import myphoto6 from '../assets/khiladilogo.png';
import myphoto7 from '../assets/khiladilogo.png';
import axios from "axios";


const ImageCardSlider = () => {

  const [shopusers, setshopusers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [vendors, setVendors] = useState([]);

//   useEffect(() => {
//     fetchUser();
// }, []);
  // Slider settings for responsiveness and autoplay
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // For tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios.get("/api/shopvendor")
      .then((response) => setVendors(response.data))
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

const filteredshopusers = shopusers.filter(user =>
  user.catProfileImage.toLowerCase().includes(searchQuery.toLowerCase()) ||
  user.catTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
  user.description.includes(searchQuery)
);

  return (
    <>
      <div className="container">
        <div className="slider-container">
          {/* Title and More Trainers Link */}
          <div className="slider-title px-3 mb-3">
            <h2>Sports Shop</h2>
          </div>
          <Slider className="myslider" {...settings}>
            {vendors.map((item) => (
              <div className="card" key={item.id}>
                <img src={`https://api.khiladiindia.com/backend/uploads/ShopImage/${item.vendorImage}`} alt={item.name} className="card-image" />
                <div className="card-content">
                  <h3 className="card-title">{item.name}</h3>
                  <p className="card-description">{item.about}</p>
                  <a href={`/Sports-Shop/${item.vendorURL}`} className="btntrainer">
                    Know More
                  </a>
                </div>
              </div>
            ))}
          </Slider>
          <Link to="/Sports-Shop" className="more-trainers-link">More Trainers</Link>
        </div>
      </div>
    </>
  );
};

export default ImageCardSlider;
