import React, { useEffect, useState } from "react";
import VendorCard from "../components/VendorCard";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

export default function VendorListingAcademy() {
  const { category } = useParams(); // Extract category from URL
  const location = useLocation(); // Get the location state (state, city filters)
  const { selectedState, selectedCity, selectedCategory } = location.state || {}; // Destructure filter data from location state

  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch vendors based on the selected category (from URL or filters)
  const fetchVendors = async () => {
    setLoading(true);
    setError(null); // Reset error before fetching
    try {
      // Determine category: URL category or selected category from location
      const categoryToFetch = selectedCategory || category;

      // Fetch vendors for the selected category
      const response = await axios.get(`/api/get_vender/cat/${categoryToFetch}`);
      if (Array.isArray(response.data)) {
        // Sort vendors based on `isPaid`
        const sortedVendors = response.data.sort((a, b) => {
          const aIsFree = a.isPaid?.toLowerCase() === "free";
          const bIsFree = b.isPaid?.toLowerCase() === "free";

          if (aIsFree && !bIsFree) return 1;
          if (!aIsFree && bIsFree) return -1;
          return 0;
        });

        setVendors(sortedVendors);
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to fetch vendors. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Apply filter logic for state and city when vendors are fetched
  const filterVendors = () => {
    const filtered = vendors.filter((vendor) => {
      const matchesState = !selectedState || vendor.state === selectedState;
      const matchesCity = !selectedCity || vendor.city.toLowerCase().includes(selectedCity.toLowerCase());
      const matchesCategory = !selectedCategory || vendor.category?.category === selectedCategory;
      return matchesState && matchesCity && matchesCategory;
    });
    setFilteredVendors(filtered);
  };

  useEffect(() => {
    fetchVendors();
  }, [selectedCategory, category]); // Fetch vendors when category changes (via URL or state)

  useEffect(() => {
    if (vendors.length > 0) {
      filterVendors(); // Apply filters when vendors or filters change
    }
  }, [selectedState, selectedCity, selectedCategory, vendors]); // Re-run filter logic when filters or vendors change

  if (loading) return <p>Loading vendors...</p>;
  if (error) return <p className="error-message">Error: {error}</p>;

  return (
    <div className="container listingcontainer">
      {filteredVendors.length > 0 ? (
        filteredVendors.map((vendor) => (
          <VendorCard key={vendor._id} data={vendor} sportId={category} />
        ))
      ) : (
        <p>No vendors found for the selected filters.</p>
      )}
    </div>
  );
}
