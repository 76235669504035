import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";

function SingleVendorUser() {
// const 
    const [userVendor, setUserVendor] = useState(null);
    const [loading, setLoading] = useState(true);  // To manage loading state
    const [error, setError] = useState(null);  // To manage error state

    const userV = JSON.parse(localStorage.getItem("user"));
    const strUserId = userV?.data?._id;
    console.log(strUserId);

    const fetchSingleUser = async () => {
        try {
            const response = await axios.get(`/api/get_vender/byid/${strUserId}`);
            setUserVendor(response.data.data);
            console.log("single use",response.data.data)
        } catch (err) {
            setError("Failed to fetch user data.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        if (strUserId) {
            console.log("Fetching data for user ID:", strUserId);  // Verify user ID
            fetchSingleUser();
        }
    }, [strUserId]);
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    
    return (
        <div>
            <div className="d-flex">
                <div className="title-bar1 col-md-6">
                    <h4>Vendor</h4>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Profile
                        </li>
                    </ol>
                </div>
                <div className="VendorMainDashboard font-weight-bold col-md-6 vendorIdOnTop" >User Id : <span style={{color:"green",}}>{userVendor._id}</span></div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table id="example" className="display table table-bordered">
                            <thead className="table-light tableHeader">
                                <tr className="tableHeader">
                                    <th>Profile</th>
                                    <th>Category</th>
                                    <th>Phone</th>
                                    <th>Services</th>
                                    <th>Password</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                   
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody >
                                {userVendor ? (
                                    <tr className="tableHeader">
                                        <td>
                                            <td>
                                                {/* Show the image if profileUrl is present */}
                                                {userVendor.profileUrl ? (
                                                    <img className="rounded-circle"
                                                        src={`https://api.khiladiindia.com/backend/uploads/profileImage/${userVendor.profileUrl.split('/').pop()}`}
                                                        // alt={user.profileUrl} 
                                                        width="50"
                                                        height="50"
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                ) : (
                                                    "NA"
                                                )}
                                            </td>
                                        </td>
                                        <td>{userVendor.category?.category || "N/A"}</td>
                                        <td>{userVendor.phone || "N/A"}</td>
                                        <td>{userVendor.isPaid}</td>
                                        <td>{userVendor.password}</td>
                                        <td>{userVendor.address?.slice(0, 20) || "N/A"}</td>
                                        <td>{userVendor.email?.slice(0, 17) || "N/A"}</td>
                                       <td>
                                            <Link
                                                to={`/dashboard/edit-user/${userVendor._id}`}
                                                className="btn btn-sm btn-primary me-2"
                                            >
                                                <CiEdit /> Edit
                                            </Link>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="8">No vendor data available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleVendorUser;
