import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../components/StayHealthy.css";
import "../../../components/owl.carousel.min.css";
import "../../../components/owl.theme.default.min.css";
import "../../../components/aos.css";
import Swal from "sweetalert2";
import khiladilogo from '../../../assets/logo.svg';
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function HomeBannerManagement() {
  const [vendors, setVendors] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [vCategory, setVCategory] = useState([])
  const [formData, setFormData] = useState({
    vendorName: "",
    vendorURL: "",
    homeBanner1: null,
    homeBanner2: null,
    homeBanner3: null,
    registerDate: "",
    expireDate: "",
  });

  const [currentBanner, setCurrentBanner] = useState(0);

  // Fetch vendors from backend API
  useEffect(() => {
    axios
      .get("/api/home-banner")
      .then((response) => {
        setVendors(response.data);
      })
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

  useEffect(() => {
    fetchAllCategoryHandler()
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/api/get_vender");
      const fetchedCategories = response.data || [];

      // Filter or process categories based on the condition
      const filteredCategories = fetchedCategories.filter(category => category.isPaid === "Paid");

      setCategories(filteredCategories);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };


const fetchAllCategoryHandler  = async() => {
  try {
    const response = await axios.get("/api/get-all-category");
    const fetchedCategories = response.data || [];
    setVCategory(fetchedCategories);
  } catch (error) {
    console.error("Error fetching vendors:", error.message);
  }
}


  useEffect(() => {
    const bannerImages = [formData.homeBanner1, formData.homeBanner2, formData.homeBanner3];
    if (bannerImages.filter(Boolean).length > 1) {
      const interval = setInterval(() => {
        setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerImages.filter(Boolean).length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleVendorSelect = (e) => {
    const vendorId = e.target.value;
    setSelectedVendorId(vendorId);
    const selectedVendor = vendors.find((ven) => ven._id === vendorId);
    if (selectedVendor) {
      setFormData({
        vendorName: selectedVendor.vendorName,
        vendorURL: selectedVendor.vendorURL,
        homeBanner1: selectedVendor.homeBanner1,
        homeBanner2: selectedVendor.homeBanner2,
        homeBanner3: selectedVendor.homeBanner3,
        registerDate: selectedVendor.registerDate,
        expireDate: selectedVendor.expireDate,
      });
    }
  };

  const handleSubmit = () => {
    if (!selectedVendorId) {
      Swal.fire("Error!", "Please select a vendor to update.", "error");
      return;
    }

    const dataToSend = new FormData();
    dataToSend.append("vendorName", formData.vendorName);
    dataToSend.append("vendorURL", formData.vendorURL);
    dataToSend.append("registerDate", formData.registerDate);
    dataToSend.append("expireDate", formData.expireDate);

    if (formData.homeBanner1) dataToSend.append("homeBanner1", formData.homeBanner1);
    if (formData.homeBanner2) dataToSend.append("homeBanner2", formData.homeBanner2);
    if (formData.homeBanner3) dataToSend.append("homeBanner3", formData.homeBanner3);

    axios
      .put(`/api/home-banner/${selectedVendorId}`, dataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        Swal.fire("Success!", "Vendor updated successfully.", "success");
        setFormData({
          vendorName: "",
          vendorURL: "",
          homeBanner1: null,
          homeBanner2: null,
          homeBanner3: null,
          registerDate: "",
          expireDate: "",
        });
        setVendors((prevVendors) =>
          prevVendors.map((vendor) =>
            vendor._id === selectedVendorId ? response.data.vendor : vendor
          )
        );
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          error.response ? error.response.data.msg : error.message,
          "error"
        );
      });
  };



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (_, next) => setCurrentIndex(next),
  };

  return (
    <div className="container">
      <h4 className="text-center mb-5">Home Page Banner Management</h4>

      {/* Vendor Carousel Slider */}
      <div className="row justify-content-center mb-5">
        <div className="col-md-10 " style={{ justifyItems: "center" }}>
          <div className="banner-img-container position-relative w-50 row d-flex" style={{ borderRadius: "10px", overflow: "hidden" }}>
            {/* Left Image Slider (First User's Images) */}
            <Link to={`/${vendors[0]?.vendorURL}`} className="w-50 leftHomeBanner p-0">
            <Slider {...settings} >
              {vendors?.[0]?.homeBanner1 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner1}`}
                    alt="First user's slider" height="600px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[0]?.homeBanner2 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner2}`}
                    alt="First user's slider" height="600px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[0]?.homeBanner3 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner3}`}
                    alt="First user's slider" height="600px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </Slider>
          </Link>

          {/* Right Top Image Slider (Second User's Images) */}
          <div className="w-50 p-0">
           
            <Link to={`/${vendors?.[1]?.vendorURL}`} className="w-100 rightTopHomeBanner p-0">
            <Slider {...settings} >
              {vendors?.[1]?.homeBanner1 && (
                <div>
                  <img
                    className="w-100 "
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner1}`}
                    alt="Second user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[1]?.homeBanner2 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner2}`}
                    alt="Second user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[1]?.homeBanner3 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner3}`}
                    alt="Second user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </Slider>
            </Link>
          

            {/* Right Bottom Image Slider (Third User's Images) */}
            <Link to={`/${vendors[2]?.vendorURL}`} className="w-100 rightBottomHomeBanner p-0">
            <Slider {...settings} >
              {vendors?.[2]?.homeBanner1 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner1}`}
                    alt="Third user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[2]?.homeBanner2 && (
                <div>
                  <img
                    className="w-100 "
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner2}`}
                    alt="Third user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
              {vendors?.[2]?.homeBanner3 && (
                <div>
                  <img
                    className="w-100"
                    src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner3}`}
                    alt="Third user's slider" height="300px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </Slider>
            </Link>
           
          </div>

          {/* Center Logo */}
          <div className="position-absolute hero-logo-container1">
            <img
              src={khiladilogo}
              alt="Hero Logo"
              className="hero-logo1"
              height="40px"
            />
          </div>
        </div>
      </div>
    </div>

      {/* Form for Vendor Details */ }
  <form className="row">
    <div className="col-md-4 mb-3">
      <label>Select Vendor</label>
      <select
        className="form-control"
        value={selectedVendorId}
        onChange={handleVendorSelect}
      >
        <option value="">Select a vendor</option>
        {vendors.map((vendor) => (
          <option key={vendor._id} value={vendor._id}>
            {vendor.vendorName}
          </option>
        ))}
      </select>
    </div>

    <div className="col-md-4 mb-3">
      <label>Vendor Name</label>
      <input
        type="text"
        name="vendorName"
        value={formData.vendorName}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>

    <div className="col-md-4 mb-3">
      <label>Vendor URL</label>
      <select
        name="vendorURL"
        value={formData.vendorURL}
        onChange={handleInputChange}
        className="form-control"
      >
        <option value="">Select Vendor</option>
        {categories.map((cat) => (
          <option key={cat._id} value={`${cat.category?.category.replace(/\s+/g, '-')}/${cat.title}`}>
            {`${cat.title}`}
          </option>
        ))}
      </select>
    </div>

    {/* Banner Image Inputs */}
    <div className="col-md-4 mb-3">
      <label>Home Banner 1</label>
      <input
        type="file"
        name="homeBanner1"
        onChange={handleFileChange}
        className="form-control"
      />
    </div>

    <div className="col-md-4 mb-3">
      <label>Home Banner 2</label>
      <input
        type="file"
        name="homeBanner2"
        onChange={handleFileChange}
        className="form-control"
      />
    </div>

    <div className="col-md-4 mb-3">
      <label>Home Banner 3</label>
      <input
        type="file"
        name="homeBanner3"
        onChange={handleFileChange}
        className="form-control"
      />
    </div>

    {/* Date Inputs */}
    <div className="col-md-4 mb-3">
      <label>Register Date</label>
      <input
        type="date"
        name="registerDate"
        value={formData.registerDate}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>

    <div className="col-md-4 mb-3">
      <label>Expire Date</label>
      <input
        type="date"
        name="expireDate"
        value={formData.expireDate}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    {/* Submit Button */}
    <div className="col-md-12 mb-3">
      <button type="button" className="btn btn-primary" onClick={handleSubmit}>
        Save Vendor
      </button>
    </div>
  </form>
    </div >
  );
}
