import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../Styles.css';
import axios from 'axios';

function CategoryMenu() {
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { sportId } = useParams();
    const navigate = useNavigate();

    // Fetch categories from the API
    const fetchCategories = () => {
        axios.get('/api/get-all-category')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };

    // Fetch categories on component mount
    useEffect(() => {
        fetchCategories();
    }, []);

    // Toggle modal visibility
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleCategoryClick = (categoryName) => {
        // Replace spaces with hyphens
        const formattedCategory = categoryName.replace(/\s+/g, '-');
        // Redirect to the category listing page
        navigate(`/${formattedCategory}`);
    };

    return (
        <>
            <style>
                {`
                    button:hover {
                        background-color: #E86F2E !important;
                        transform: scale(1.05) !important;
                    }

                    /* Modal styles */
                    .modal-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1050;
                        backdrop-filter: blur(10px);
                    }
                    .modal-content {
                        background: white;
                        width: 95%;
                        max-width: 800px;
                        border-radius: 10px;
                        padding: 20px;
                        overflow-y: auto;
                        max-height: 90%;
                    }
                    .modal-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #ddd;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                    }
                    .close-button {
                        background: none;
                        border: none;
                        font-size: 20px;
                        cursor: pointer;
                    }
                    /* Responsive categories grid */
                    .categories-grid {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 10px;
                    }
                    .category-menu-item {
                        width: 100px;
                        height: 100px;
                        background-color: #2991c8;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: white;
                        font-size: 12px;
                        margin: 5px;
                        transition: transform 0.2s;
                        cursor: pointer;
                    }
                    .category-menu-item img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        margin-bottom: 5px;
                    }
                `}
            </style>

            
            <div className="mt-5">
                <div className="container-fluid">
                    <div className="categoryMenuList w-30 d-flex flex-column align-items-center">
                        {/* Categories Row */}
                        <div className="row justify-content-center mb-4">
                            {categories.slice(0, 16).map((category) => (
                                <div
                                    key={category._id}
                                    className="category-menu-item col-6 col-md-2 d-flex align-items-center flex-column text-center justify-content-center"
                                    style={categoryItemStyle}
                                    onClick={() => handleCategoryClick(category.category)}
                                >
                                    <img
                                        src={`https://api.khiladiindia.com/backend/uploads/categoryIcons/${category.categoryIcon}`}
                                        alt={category.category || category.label}
                                        style={imageStyle}
                                    />
                                    <p style={paragraphStyle}>{category.category}</p>
                                </div>
                            ))}
                        </div>
                        {/* Button Section */}
                        <div className="row d-flex justify-content-end mt-2 mb-2" style={{ width: '100%' }}>
                            <div className="col-md-2 me-5 d-flex justify-content-end">
                                <button style={moreServicesButtonStyle} onClick={toggleModal}>
                                    More Services
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal-overlay" onClick={toggleModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h5>All Categories</h5>
                            <button style={closeModalCatPopUp} className="close-button" onClick={toggleModal}>
                                &times;
                            </button>
                        </div>
                        <div className="categories-grid">
                            {categories.slice(16, 10000).map((category) => (
                                <div
                                    key={category._id}
                                    className="category-menu-item"
                                    onClick={() => handleCategoryClick(category.category)}
                                >
                                    <img
                                        src={`https://api.khiladiindia.com/backend/uploads/categoryIcons/${category.categoryIcon}`}
                                        alt={category.category || category.label}
                                    />
                                    <p style={categoryNameInPopUP}>{category.category}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const categoryItemStyle = {
    width: "150px",
    height: "150px",
    borderRadius: "20px",
    backgroundColor: "#2991c8",
    padding: "10px",
    margin: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s",
    cursor: "pointer",
};

const closeModalCatPopUp = {
    backgroundColor: "orange",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
};

const imageStyle = {
    backgroundColor: "#2991c8",
    borderRadius: "50%",
    width: "100px",
    padding: "2px",
    overflow: "hidden",
    height: "100px",
};

const paragraphStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
};

const linkStyle1 = {
    textDecoration: "none",
    color: "inherit",
};

const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    height: "100px",
};

const moreServicesButtonStyle = {
    padding: "10px 20px",
    borderRadius: "5px",
    backgroundColor: "#2991c8",
    color: "white",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s, transform 0.3s",
};

const categoryNameInPopUP = {
    fontSize: "10px",
    lineHeight: "1.6",
};

export default CategoryMenu;
