import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../components/StayHealthy.css";
import "./../../../components/owl.carousel.min.css";
import "./../../../components/owl.theme.default.min.css";
import "./../../../components/aos.css";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";

export default function DefenceTrainingCenter() {
  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]); // For categories from get_vender
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [formData, setFormData] = useState({
    vendorName: "",
    about: "",
    vendorURL: "",
    profileImage: "",
    registerDate: "",
    expireDate: "",
  });

  const { category } = useParams();

  // Fetch all vendors
  const fetchVendors = async () => {
    try {
      const response = await axios.get("/api/defencevenders");
      setVendors(response.data || []);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/api/get_vender");
      const allCategories = response.data || [];
      // Filter categories to include only Defence vendors
      const defenceCategories = allCategories.filter(
        (category) =>category.isPaid === "Paid" &&  category.category._id === "67770fcef02028ecec4865cf"
      );
      setCategories(defenceCategories);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
    fetchCategories();
  }, []);

  const handleVendorSelect = (e) => {
    const vendorId = e.target.value;
    setSelectedVendorId(vendorId);

    const selectedVendor = vendors.find((ven) => ven._id === vendorId);
    if (selectedVendor) {
      setFormData({
        id: selectedVendor._id,
        vendorName: selectedVendor.vendorName,
        about: selectedVendor.about,
        vendorURL: selectedVendor.vendorURL,
        profileImage: null,
        registerDate: selectedVendor.registerDate,
        expireDate: selectedVendor.expireDate,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const form = new FormData();
    form.append("vendorName", formData.vendorName);
    form.append("vendorURL", formData.vendorURL);
    form.append("about", formData.about);
    form.append("expireDate", formData.expireDate);
    form.append("registerDate", formData.registerDate);
    if (formData.profileImage) form.append("profileImage", formData.profileImage);
  
    try {
      if (formData.id) {
        await axios.put(`/api/defencevenders/${formData.id}`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        Swal.fire("Success", "Vendor updated successfully!", "success");
      } else {
        Swal.fire("Error", "No vendor selected for update.", "error");
      }
  
      setFormData({
        id: "",
        vendorName: "",
        about: "",
        vendorURL: "",
        profileImage: null,
        registerDate: "",
        expireDate: "",
      });
      fetchVendors();
    } catch (error) {
      console.error("Error updating vendor:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to update vendor.", "error");
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:value
        
    }));
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        Swal.fire("Error", "Please upload a valid image (JPEG, PNG)", "error");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        Swal.fire("Error", "Image size must be less than 5MB", "error");
        return;
      }
      setFormData((prev) => ({
        ...prev,
        profileImage: file,
      }));
    }
  };

  
  
  return (
    <div className="container">
      <div className="site-section1" id="classes-section1">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8 section-heading">
              <h2 className="heading">Defence Training Center</h2>
            </div>
          </div>
          <div className="row u--zoomIn">
            {vendors.map((vendor, index) => (
              <Link
              to={`/Deffence-Center/${vendor.vendorURL}`}
              className="col-lg-6"
              key={vendor._id}
            >
              <div className="class-item d-flex align-items-center">
                <div className="class-item-thumbnail">
                  <img
                    src={`https://api.khiladiindia.com/backend/uploads/homepage/${vendor.profileImage}`}
                    alt={vendor.vendorName}
                    height="200px"
                  />
                </div>
                <div className="class-item-text">
                  <h2>{vendor.vendorName}</h2>
                  <span>{vendor.about}</span>
                </div>
              </div>
            </Link>
            
            ))}
          </div>
        </div>
      </div>

      {/* Forms for Adding/Updating Vendors */}
      <div className="row">
        <div className="col-md-3">
          <div className="form-group my-4">
            <label>Select Vendor</label>
            <select
              className="form-control"
              value={selectedVendorId}
              onChange={handleVendorSelect}
            >
              <option value="">Select a vendor</option>
              {vendors.map((vendor) => (
                <option key={vendor._id} value={vendor._id}>
                  {vendor.vendorName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="mb-5">
          <h3>Edit Vendor</h3>
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-md-2 mb-3">
              <label>Vendor Name</label>
              <input
                type="text"
                name="vendorName"
                value={formData.vendorName}
                onChange={handleInputChange}
                placeholder="Enter vendor name"
                className="form-control"
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>About</label>
              <input
                type="text"
                name="about"
                value={formData.about}
                onChange={handleInputChange}
                placeholder="About"
                className="form-control"
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Vendor Profile Image</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="form-control"
                name="profileImage"
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Vendor URL</label>
              <select
                name="vendorURL"
                value={formData.vendorURL}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select Defence Vendor</option>
                {categories.map((cat) => (
                  <option key={cat._id} value={cat.title}>
                    {` ${cat.title}`} {/* Display the appropriate field for vendor title */}
                  </option>
                ))}
              </select>

            </div>
            <div className="col-md-2 mb-3">
              <label>Register Date:</label>
              <input
                type="date"
                name="registerDate"
                value={formData.registerDate}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Expire Date:</label>
              <input
                type="date"
                name="expireDate"
                value={formData.expireDate}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Update Vendor
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
