import React, { useEffect, useState } from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import p1 from "../assets/img_1.jpg";
import p2 from "../assets/img_2.jpg";
import p3 from "../assets/img_3.jpg";
import p4 from "../assets/img_4.jpg";
import p5 from "../assets/img_5.jpg";
import { Link } from 'react-router-dom';
// import 'animate.css';
import '../components/animation-library/css/zoom.css'
import axios from 'axios';

export default function FitnessTrainer() {

  const [VenderUsers, setVenderUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [vendors, setVendors] = useState([]);


  const filteredVenderUsers = VenderUsers.filter(user =>
    user.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.instructor.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.duration.includes(searchQuery)
  );

  const fetchVendors = async () => {
    try {
      const response = await axios.get("/api/fitnessTrainer");
      setVendors(response.data || []);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);


  return (
    <div className="site-section" id="classes-section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8 section-heading">
            <h2 className="heading mb-3">Fitness Trainer & Sports Coach</h2>
            <p>Our gym offers a variety of classes targeting different muscle groups, designed by expert trainers. Whether you're looking to build strength, tone your body, or improve your endurance, we have the perfect workout routine for you.</p>
          </div>
        </div>

        <div className="row u--zoomIn">
          {/* Map over exercises to dynamically generate content */}
          {vendors.map((exercise) => (
            <div className="col-lg-6" key={exercise.id}>
              {/* Wrap the entire card in the Link */}
              <Link to={`/Fitness-Trainer/${exercise.vendorURL}`} className="class-item d-flex align-items-center ">
                <div className="class-item-thumbnail">
                  <img src={`https://api.khiladiindia.com/backend/uploads/homepage/${exercise.profileImage1}`} alt={exercise.vendorName} />
                </div>
                <div className="class-item-text">
                  <h2 className='text-white'>{exercise.vendorName}</h2>
                  <span>{exercise.about}</span>
                </div>
              </Link>
            </div>
          ))
          }
          <div className="center-button">
            <Link to="/Fitness-Trainer" className="more-trainers-link">More Services</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
