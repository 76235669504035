import React, { useRef } from "react";
import squash from "../assets/squash.jpg";
import volleyball from "../assets/volleyball.jpg";
import football from "../assets/Football.jpg";
import tennis from "../assets/Tennis.jpg";
import pingpong from "../assets/ping pong.jpg";
import carrom from "../assets/carrom.jpg";
import basketball from "../assets/Basketball.jpg";

const categories = [
  { name: "Squash", image: squash },
  { name: "Ping Pong", image: pingpong },
  { name: "Basketball", image: basketball },
  { name: "Tennis", image: tennis },
  { name: "Football", image: football },
  { name: "Carrom", image: carrom },
  { name: "Volleyball", image: volleyball },
  { name: "Squash", image: squash },
  { name: "Ping Pong", image: pingpong },
  { name: "Basketball", image: basketball },
  { name: "Tennis", image: tennis },
  { name: "Football", image: football },
  { name: "Carrom", image: carrom },
  { name: "Volleyball", image: volleyball },
];
const SportsEquipmentCat = () => {
  const scrollContainerRef = useRef(null);

  const styles = {
    container: {
      textAlign: "center",
      position: "relative",
      color: "#000",
      overflow: "hidden", // Ensure content does not overflow
    },

    scrollContainer: {
      display: "flex",
      overflowX: "hidden", // Enable horizontal scrolling
      gap: "40px",
      scrollBehavior: "smooth", // Smooth scrolling
      padding: "20px 0",
    },
    cardContainer: {
      flex: "0 0 auto", // Cards should not shrink
      textAlign: "center",
    },
    card: {
      width: "150px",
      height: "150px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      overflow: "hidden",
      backgroundColor: "pink",
      transition: "transform 0.2s ease-in-out",
      cursor: "pointer",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    name: {
      marginTop: "10px",
      fontSize: "16px",
      //   fontWeight: "bold",
      color: "#333",
    },
    arrowButton1: {
      position: "absolute",
      top: "35%",
      //   transform: "translateY(-50%)",
      backgroundColor: "#2991c8",
      color: "white",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      zIndex: 10,
      fontSize: "20px",
    },
    leftArrow1: {
      left: "10px",
    },
    rightArrow1: {
      right: "10px",
    },
    heading: {
      fontWeight: "500",
    }

  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className="container" style={styles.container}>
      <h2 style={styles.heading}>Sports wear & sports equipments</h2>
      {/* Left Arrow */}
      <button
        style={{ ...styles.arrowButton1, ...styles.leftArrow1 }}
        onClick={scrollLeft}
      >
        &#8249;
      </button>

      {/* Scrollable Container */}
      <div style={styles.scrollContainer} ref={scrollContainerRef}>
        {categories.map((category, index) => (
          <div key={index} style={styles.cardContainer}>
            <div
              style={styles.card}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <img
                src={category.image}
                alt={category.name}
                style={styles.image}
              />
            </div>
            <p style={styles.name}>{category.name}</p>
          </div>
        ))}
      </div>

      {/* Right Arrow */}
      <button
        style={{ ...styles.arrowButton1, ...styles.rightArrow1 }}
        onClick={scrollRight}
      >
        &#8250;
      </button>
      <div className="center-button">
        <a href="/" className="btn main-button" id="Second-button">More Services</a>
      </div>
    </div>
  );
};

export default SportsEquipmentCat;
