import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function TrainerFormManagement() {
  const navigate = useNavigate();

  const [vendorCat, setVendorCat] = useState([]);
  const [formData, setFormData] = useState({
    profileImageTrainer: "",
    phone: "",
    category: "",
    address: "",
    experience: "",
    mapLocation: "",
    startDate: "",
    expireDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchCategories = () => {
    axios
        .get('/api/get-all-trainer-cat')
        .then((response) => {
            setVendorCat(response.data);
        })
        .catch((error) => {
            console.error('Error fetching categories:', error);
        });
};

  const handleFileChange = (e) => {
    setFormData({ ...formData, profileImageTrainer: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSend = new FormData();
    dataToSend.append("profileImageTrainer", formData.profileImageTrainer);
    dataToSend.append("phone", formData.phone);
    dataToSend.append("category", formData.category);
    dataToSend.append("address", formData.address);
    dataToSend.append("experience", formData.experience);
    dataToSend.append("mapLocation", formData.mapLocation);
    dataToSend.append("startDate", formData.startDate);
    dataToSend.append("expireDate", formData.expireDate);

    axios
      .post("/api/trainer", dataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("Trainer registered successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error registering trainer:", error);
      });
  };

  useEffect(() => {
    fetchCategories();
}, []);

  const handleCloseForm = () => {
    navigate("/dashboard/total-trainers");
  };

  return (
    <div className="container">
      <div className="" onClick={handleCloseForm}></div>
      <div className="">
        <form className="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-6">
              <h3>Trainer Details</h3>
              <div className="mb-3">
                <label htmlFor="profileImageTrainer" className="form-label">Profile Image</label>
                <input
                  type="file"
                  name="profileImageTrainer"
                  onChange={handleFileChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="category" className="form-label">Category</label>
                <select
                  id="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Category *</option>
                  {vendorCat.map((cat) => (
                    <option key={cat._id} value={cat.category}>
                      {cat.category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter address"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="experience" className="form-label">Experience</label>
                <input
                  type="text"
                  name="experience"
                  value={formData.experience}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter experience"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="mapLocation" className="form-label">Map Location</label>
                <input
                  type="text"
                  name="mapLocation"
                  value={formData.mapLocation}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter map location"
                  required
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <h3>Duration</h3>
              <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="expireDate" className="form-label">Expire Date</label>
                <input
                  type="date"
                  name="expireDate"
                  value={formData.expireDate}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Proceed to Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleCloseForm}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TrainerFormManagement;
