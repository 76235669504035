import React from 'react'
import khiladilogo from '../assets/khiladilogo.png'
import { Link } from 'react-router-dom'
import locationIcon from '../assets/images/location.png'
import callIcon from '../assets/images/call.png'

function Footer() {
  return (
    <div>
      <footer id="footer" className="footer mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="full">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <a href="/"><img src={khiladilogo} alt="/" /></a>
                                    </div>
                                    <p>Most of our events have hard and easy route choices as we are always keen to encourage new riders.</p>
                                    <ul className="social-icons pull-left">
                                        <li><a className="facebook" href="/"><i className="fa-brands fa-facebook"></i></a></li>
                                        <li><a className="twitter" href="/"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a className="youtube" href="/"><i className="fa-brands fa-youtube"></i></a></li>
                                        <li><a className="pinterest" href="/"><i className="fa-brands fa-pinterest"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <Link className='mt-5 termandCon' to="/termAndConditions" style={{fontSize:'14px'}}>Term And Conditions</Link>
                        </div>

                        <div className="col-md-4">
                            <div className="full">
                                <div className="footer-widget">
                                    <h3>Contact Us</h3>
                                    <ul className="address-list ">
                                        <li className='d-flex' style={{alignItems:"center"}}><img style={{height:"24px",marginRight:"15px"}} src={locationIcon} alt="" /><p>Khiladi India office near Krishna Sagar Hotel gangneher Muradnagar near, near Krishna Sagar Hotel, Ghaziabad, Uttar Pradesh, 201206</p></li>
                                        <li className='d-flex' style={{alignItems:"center"}}><img style={{height:"24px",marginRight:"15px"}} src={callIcon} alt="" /><p>91-827 984 8804</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="full">
                                <div className="contact-footer mt-3">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13988.06295289815!2d77.5238279!3d28.7787901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf5b574c74883%3A0x47234f4e49e96286!2sKhiladi%20India%20Sports%20Services!5e0!3m2!1sen!2sin!4v1727206716560!5m2!1sen!2sin" width="600" height="300" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <p>KhiladIndia Copyright © 2024 by <a href="https://krystalmovies.com/" style={{ color: "#E86F2E", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">Krystal Movies P Ltd</a></p>
                    </div>
                </div>
            </footer>
    </div>
  )
}

export default Footer
