import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import VenderListingAcademy from './pages/VenderListingAcademy';
import VenderPage1 from './pages/VenderPage1';
import PopUpMessage from './components/PopUpMessage';
import './assets/css/style.css';
import Userregistor from './modules/dashboard/auth/Userregistor';
import Dashboard from './modules/dashboard/Dashboard';
import Users from "./modules/dashboard/usermanagment/Users";
import UserList from './modules/dashboard/auth/UserList';
import AddUser from './modules/dashboard/usermanagment/AddUser';
import EditUser from './modules/dashboard/usermanagment/EditUser';
import EmptyPage from './components/EmptyPage';
import DeffenceTrainingCe from './components/DeffenceTrainingCe';
import TotalVenders from './modules/dashboard/deffenceAcademyUser/TotalVenders';
import DashboardHome from './modules/dashboard/DashboardHome';
import TotalSportShop from './modules/dashboard/SportsShopManagement/TotalSportShop';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import TotalCategory from './modules/dashboard/Category/TotalCategory';
import AddCategory from './modules/dashboard/Category/AddCategory';
import EditCategory from './modules/dashboard/Category/EditCategory';
import SingleVendorUser from './modules/dashboard/usermanagment/SingleVendorUser';
import ScrollToTop from './components/scrollBar/ScrollBar';
import axios from 'axios';
import DifenceTrainingManagement from './modules/dashboard/HomePageManagement/DifenceTrainingManagement';
import HomeBannerManagement from './modules/dashboard/HomePageManagement/HomeBannerManagement';
import JobPostForm from './modules/dashboard/JobPost/JobPostForm';
import SportsShopManagement from './modules/dashboard/HomePageManagement/SportsShopManagement';
import TermsOfUse from './pages/TermAndConditions';
import FitnessTrainerManagement from './modules/dashboard/HomePageManagement/FitnessTrainerManagement';
import SportsAcademyManagement from './modules/dashboard/HomePageManagement/SportsAcademyManagement';
import JobsListing from './modules/dashboard/JobPost/JobsListingPage';
import KabaddiTrainerManagement from './modules/dashboard/HomePageManagement/KabaddiTrainerManagement';
import TrainerListing from './modules/dashboard/Trainer/OtherTrainer/TrainerListing';
import JobApproval from './modules/dashboard/JobPost/JobApproval';
import TrainerFormManagement from './modules/dashboard/Trainer/TrainerUsers/AddTrainer';
import TrainerRegistration from './pages/TrainerPaymentForm';
import TotalTrainers from './modules/dashboard/Trainer/TrainerUsers/totalTrainers';
import EditTrainer from './modules/dashboard/Trainer/TrainerUsers/editTrainer';
import EditTrainerCategory from './modules/dashboard/Trainer/category/EditTrainerCategory';
import AddTrainerCategory from './modules/dashboard/Trainer/category/AddTrainerCategory';
import TotalTrainerCategory from './modules/dashboard/Trainer/category/TotalTrainerCategory';
import CreateAccount from './pages/CreateAccount';
import UpdateJobForm from './modules/dashboard/JobPost/EditJob';
import Setting from './modules/dashboard/Setting';


// axios.defaults.baseURL = "http://localhost:5000";
axios.defaults.baseURL = "https://api.khiladiindia.com";

function App() {
  const location = useLocation();
  const isDashboardRoute = location.pathname.includes("dashboard");
  return (
    <>
      {!isDashboardRoute && <Navbar />}
      {!isDashboardRoute && <PopUpMessage />}
      <main
        style={
          window.location.href.includes("dashboard")
            ? { paddingTop: "0px"}
            : { paddingTop: "100px"}
        }
      >
        <ScrollToTop /> 
        <Routes>
          <Route path='/KhiladiIndia' element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path='/:category' element={<VenderListingAcademy />} />
          <Route path='/:category/:title' element={<VenderPage1 />} />
          <Route path='/termAndConditions' element={<TermsOfUse />} />
          <Route path='/JobsListing' element={<JobsListing/>}/>
          <Route path='/trainer-listing' element={<TrainerListing/>}/>
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="createaccount" element={<Userregistor />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>
            <Route path='/dashboard' element={<DashboardHome />} />
            <Route path='total-users' element={<Users />} />
            <Route path='/dashboard/:title' element={<SingleVendorUser />} />
            <Route path='total-venders' element={<TotalVenders />} />
            <Route path='total-SportShop' element={<TotalSportShop />} />
            {/* homepage dashboard  start*/}
            <Route path='HomeBanner-Management' element={<HomeBannerManagement/>} />
            <Route path='Difence-training-home' element={<DifenceTrainingManagement/>} />
            <Route path="Shop-Management" element={<SportsShopManagement />} />
            <Route path='Difence-training-home' element={<DifenceTrainingManagement/>} />
            <Route path='Fitness-trainer-home' element={<FitnessTrainerManagement/>} />
            <Route path='Sports-Academy' element={<SportsAcademyManagement/>}/>
            <Route path='Kabaddi-Trainers' element={<KabaddiTrainerManagement/>}/>
            {/* homepage dashboard end */}
            <Route path='add-user' element={<AddUser />} />
            <Route path="edit-user/:id" element={<EditUser />} />
            <Route path='total-category' element={<TotalCategory />} />
            <Route path='add-category' element={<AddCategory />} />
            <Route path="edit-category/:id" element={<EditCategory />} />
            <Route path="trainer-add" element={<TrainerFormManagement />} />
            <Route path="total-trainers" element={<TotalTrainers />} />
            <Route path="trainer-edit/:id" element={<EditTrainer />} />
            <Route path='total-trainer-category' element={<TotalTrainerCategory />} />
            <Route path='add-trainer-category' element={<AddTrainerCategory />} />
            <Route path="edit-trainer-category/:id" element={<EditTrainerCategory />} />
            <Route path="Add-jobs" element={<JobPostForm />} />
            <Route path='job-edit/:id' element={<UpdateJobForm/>}/>
            <Route path='job-approval' element={<JobApproval/>}/>
            <Route path='setting' element={<Setting/>}/>
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path='/post-job' element={<CreateAccount />} />
          <Route path="/sports" element={<EmptyPage />} />
          <Route path="/trainer-details" element={<TrainerRegistration />} />
         
          <Route path="usermanagment/userlist" element={<UserList />} />
          <Route path="/defenceacademy" element={<DeffenceTrainingCe />} />
        </Routes>
      </main>
      {!isDashboardRoute && <Footer />}
    </>
  );
}
export default App;