import React from 'react';
import './RatedTrainers.css';
import myphoto1 from '../assets/khiladilogo.png';
import { Link } from 'react-router-dom';

export default function RatedTrainers() {
  return (
    <div className="latest-news mt-1">
      <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-12 title-section text-center">
            <h2>Top Rated Services</h2>
          </div>
        </div>
        <div className="row no-gutters">
          {/* Card 1 */}
          <Link to="/Swimming-Pool" className="col-md-4">
            <div className="post-entry">
              <a href="/">
                <img src={myphoto1} alt="Swimming Pool Services" className="img-fluid" />
              </a>
              <div className="caption">
                <div className="caption-inner">
                  <h3 className="mb-3">Swimming Pool Services</h3>
                </div>
              </div>
            </div>
          </Link>

          {/* Card 2 */}
          <Link to="/Infrastructure" className="col-md-4">
            <div className="post-entry">
              <a href="/">
                <img src={myphoto1} alt="Sports Ground Construction & Maintenance" className="img-fluid" />
              </a>
              <div className="caption">
                <div className="caption-inner">
                  <h3 className="mb-3">Sports Ground Construction & Maintenance</h3>
                </div>
              </div>
            </div>
          </Link>

          {/* Card 3 */}
          <Link to="/Equipments" className="col-md-4">
            <div className="post-entry">
              <a href="/">
                <img src={myphoto1} alt="Sports Academy Equipments & Operating Providers" className="img-fluid" />
              </a>
              <div className="caption">
                <div className="caption-inner">
                  <h3 className="mb-3">Sports Academy Equipments & Operating Providers</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {/* Button Section */}
        <div className="center-button">
          <a href="/" className="btn main-button" id="Second-button">More Top Services</a>
        </div>
      </div>
    </div>
  );
}
