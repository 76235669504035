import React, { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import Swal from 'sweetalert2';
import myphoto1 from '../../../assets/myphoto1.jpg';
import myphoto2 from '../../../assets/myphoto2.jpg';
import myphoto3 from '../../../assets/myphoto3.png';
import myphoto4 from '../../../assets/myphoto4.jpg';
import myphoto5 from '../../../assets/myphoto5.jpg';
import myphoto6 from '../../../assets/myphoto6.jpg';
import myphoto7 from '../../../assets/myphoto7.jpg';
import SPORTSHOPDATA from "../../../dummyData/shopdb.json";

import axios from 'axios';

const TotalSportShop = () => {
   
    const [shopusers, setshopusers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

// Sample data for the image slider
// const imageData = [
//   {
//     id: 1,
//     imageUrl: [myphoto1],
//     title: "Card Title 1",
//     description: "Description for card 1",
//   },
//   {
//     id: 2,
//     imageUrl: [myphoto2],
//     title: "Card Title 2",
//     description: "Description for card 2",
//   },
//   {
//     id: 3,
//     imageUrl: [myphoto3],
//     title: "Card Title 3",
//     description: "Description for card 3",
//   },
//   {
//     id: 4,
//     imageUrl: [myphoto4],
//     title: "Card Title 4",
//     description: "Description for card 4",
//   },
//   {
//     id: 5,
//     imageUrl: [myphoto5],
//     title: "Card Title 5",
//     description: "Description for card 5",
//   },
//   {
//     id: 6,
//     imageUrl: [myphoto6],
//     title: "Card Title 6",
//     description: "Description for card 6",
//   },
//   {
//     id: 7,
//     imageUrl: [myphoto7],
//     title: "Card Title 7",
//     description: "Description for card 7",
//   },
// ];
   

   
useEffect(() => {
    fetchUser();
}, []);


const fetchUser = () => {
    axios.get('http://localhost:3002/shopusers')
        .then((response) => {
            console.log(response.data);
            setshopusers(response.data);
        })
        .catch((error) => {
            console.error('Error fetching users:', error);
        });
};

const deleteHandleUser = (id) => {

    // SweetAlert2 confirmation dialog
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(`http://localhost:3002/shopusers/${id}`)
                    .then(() => {

                        fetchUser();
                        Swal.fire(
                            'Deleted!',
                            'User has been deleted.',
                            'success'
                        );
                    })
                    .catch((error) => {
                        console.error('Error deleting user:', error);
                        Swal.fire(
                            'Error!',
                            'There was an error deleting the user.',
                            'error'
                        );
                    });
            }
        });
};
const filteredshopusers = shopusers.filter(user =>
    user.catProfileImage.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.catTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.description.includes(searchQuery)
);

    return (
        <main className="content px-3 py-2">
            <div className="container-fluid">
                <div className="title-bar1">
                    <h4>Sport Shop Management</h4>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Users List</li>
                    </ol>
                </div>
                <div className='row'>

                    <div className='table-head row'>
                        <div className='col-md-4'>
                            <div className='totle-use'>
                                <h4>Total Users: <b>{filteredshopusers.length}</b></h4>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    placeholder="Search by name, email, or phone"
                                    className="form-control"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-md-2'><Link to="/dashboard/add-SportShop" className="btn btn-success mb-3"><IoIosAddCircleOutline />
                            Add User</Link></div>
                    </div></div>


                <div className="row">
                    <div className="col-md-12">
                        <table className="table ">
                            <thead className="table-light">
                                <tr>
                                    <th>ID</th>
                                    <th>image</th>
                                    <th>title</th>
                                    <th>description</th>
                                    {/* <th>duration</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredshopusers.length > 0 ? filteredshopusers.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>
                                            <img
                                                src={user.catProfileImage}
                                                // alt={user.catTitle}
                                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                            />
                                        </td>
                                        <td>{user.catTitle}</td>
                                        <td>{user.description}</td>
                                        {/* <td>{user.duration}</td> */}
                                        <td>
                                            <Link to={`/dashboard/edit-SportShop/${user.id}`} className='btn btn-primary me-2'><CiEdit />
                                                Edit</Link>
                                            <button
                                                type="button"
                                                className='btn btn-danger'
                                                // Pass the user ID here
                                                onClick={() => deleteHandleUser(user.id)}>
                                                <MdDeleteOutline />
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No users found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TotalSportShop;
