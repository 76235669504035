import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import testimonial1 from "../assets/testimonial1.png";
import testimonial2 from "../assets/myphoto2.jpg";
import testimonial3 from "../assets/myphoto3.png";
import testimonial4 from "../assets/myphoto4.jpg";
import testimonial5 from "../assets/testimonial5.png";
import testimonial6 from "../assets/testimonial6.jpg";
import testimonial7 from "../assets/testimonial7.png";


const TestimonialSection = () => {
  // Testimonial data
  const testimonials = [
    {
      name: "Tanya Yadav",
      role: "Basketball Player",
      image: testimonial1,
      comment:
        "Basketball has taught me discipline and teamwork. The game keeps me motivated and focused, helping me grow every day.",
      sportComment:
        "Tanya dreams of representing her state in basketball and believes her hard work will lead to success.",
    },
    {
      name: "Monty Thakur",
      role: "Football Enthusiast",
      image: testimonial2,
      comment:
        "Football is my passion and keeps me energized. The thrill of the game inspires me to face challenges head-on.",
      sportComment:
        "Monty excels as a midfielder and balances academics with football practice to achieve his dreams.",
    },
    {
      name: "Ayush Singh",
      role: "Cricket Player",
      image: testimonial3,
      comment:
        "Cricket has taught me patience and strategy. Every match is a lesson in growth, both on and off the field.",
      sportComment:
        "Ayush is a talented all-rounder who enjoys analyzing professional cricket to improve his game.",
    },
    {
      name: "Vineet Yadav",
      role: "Badminton Player",
      image: testimonial4,
      comment:
        "Badminton is my passion and stress-buster. Playing sharpens my focus and helps me stay determined to improve.",
      sportComment:
        "Vineet aims to compete in state-level tournaments and works daily to enhance his skills.",
    },
    {
      name: "Rohit Sharma",
      role: "Swimmer",
      image: testimonial5, // Replace with a new image if available
      comment:
        "Swimming has been my passion since childhood. It keeps me energized, focused, and ready to take on challenges.",
      sportComment:
        "Rohit is a national-level swimmer who trains rigorously to achieve excellence in the pool.",
    },
    {
      name: "Anjali Verma",
      role: "Tennis Player",
      image: testimonial6, // Replace with a new image if available
      comment:
        "Tennis has taught me resilience. Every game challenges me to improve and stay consistent in my efforts.",
      sportComment:
        "Anjali is committed to tennis and aspires to participate in international tournaments.",
    },
    {
      name: "Priya Das",
      role: "Runner",
      image: testimonial7, // Replace with a new image if available
      comment:
        "Running gives me freedom and keeps me fit. It’s a meditative experience that strengthens my body and mind.",
      sportComment:
        "Priya participates in marathons and encourages others to embrace fitness and healthy living.",
    },
  ];
  
      


  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Testimonial
          </h6>
          <h1 className="mb-5">Our Customers Say!</h1>
        </div>
        <OwlCarousel
          className="owl-carousel testimonial-carousel position-relative"
          loop
          margin={10}
          nav
          items={2} // Display 2 testimonials at a time
          responsive={{
            0: { items: 1 }, // 1 item for small screens
            768: { items: 2 }, // 2 items for medium screens and above
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-item text-center" key={index}>
              <img
                className="border rounded-circle p-2 mx-auto mb-3"
                src={testimonial.image}
                alt={testimonial.name}
                style={{ width: "80px", height: "80px" }}
              />
              <h5 className="mb-0">{testimonial.name}</h5>
              <p>{testimonial.role}</p>
              <div className="testimonial-text bg-light1 text-center p-4">
                <p className="mb-0">{testimonial.comment}</p>
                <small className="text-muted d-block mt-2">
                  {testimonial.sportComment}
                </small>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default TestimonialSection;
