import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../../components/SliderBanner.css";
import Swal from "sweetalert2";


export default function SportsShopManagement() {
    const [vendors, setVendors] = useState([]);
    const [selectedVendorId, setSelectedVendorId] = useState("");
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        about: "",
        vendorURL: "",
        vendorImage1: "",
        registerDate: "",
        expireDate: "",
    });

    const fetchCategories = async () => {
        try {
            const response = await axios.get("/api/get_vender");
            const allCategories = response.data || [];
            // Filter categories to include only Defence vendors
            const defenceCategories = allCategories.filter(
                (category) => category.isPaid === "Paid" && category.category._id === "67736ce8f6abca00c3009cc7"
            );
            setCategories(defenceCategories);
        } catch (error) {
            console.error("Error fetching categories:", error.message);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);


    // Fetch vendors from backend API
    useEffect(() => {
        axios.get("/api/sportsAcademy")
            .then((response) => setVendors(response.data))
            .catch((error) => console.error("Error fetching vendors:", error));
    }, []);


    const handleVendorSelect = (e) => {
        const vendorId = e.target.value;
        setSelectedVendorId(vendorId);

        const selectedVendor = vendors.find((ven) => ven._id === vendorId);
        if (selectedVendor) {
            setFormData({
                id: selectedVendor._id,
                name: selectedVendor.name,
                about: selectedVendor.about,
                vendorURL: selectedVendor.vendorURL,
                vendorImage1: null,
                registerDate: selectedVendor.registerDate,
                expireDate: selectedVendor.expireDate,
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]:value
        }));
    };


    // Handle file input for image
    const handleFileChange = (e) => {
        setFormData({ ...formData, vendorImage1: e.target.files[0] });  // Ensure "vendorImage11" is being updated correctly
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const form = new FormData();
        form.append("name", formData.name);
        form.append("vendorURL", formData.vendorURL); // Use vendorURL as-is
        form.append("about", formData.about);
        form.append("expireDate", formData.expireDate);
        form.append("registerDate", formData.registerDate);
        if (formData.vendorImage1) form.append("vendorImage1", formData.vendorImage1);
        
        try {
            if (formData.id) {
                // Update existing vendor
                await axios.put(`/api/sportsAcademy/${formData.id}`, form, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                Swal.fire("Success", "Vendor updated successfully!", "success");
            } else {
                Swal.fire("Error", "Please select a vendor to update.", "error");
            }
    
            // Reset form after update
            setFormData({
                id: "",
                name: "",
                about: "",
                vendorURL: "",
                vendorImage1: null,
                registerDate: "",
                expireDate: "",
            });
    
            // Re-fetch vendors after update
            axios
                .get("/api/sportsAcademy")
                .then((response) => setVendors(response.data))
                .catch((error) => console.error("Error fetching vendors:", error));
    
        } catch (error) {
            console.error("Error submitting vendor:", error.response?.data || error.message);
            Swal.fire("Error", "Failed to update vendor.", "error");
        }
    };
    
      

    return (
        <>

            <div className="container">
                {/* Slider Section */}
                <div className="container mt-5">
                    {/* Title and Link Row */}
                    <div className="row justify-content-center mt-5 mb-4">
                        <div className="col-12 text-center">
                            <h2>Sports Academy</h2>
                        </div>
                    </div>

                    {/* Service Cards */}
                    <div className="row row-gap-4">
                        {vendors.length > 0 &&
                            vendors.map((item) => (
                                <div className="" style={{ width: "20%" }} key={item.id} >
                                    <div className="card  px-0 pt-0">
                                        <img src={`https://api.khiladiindia.com/backend/uploads/ShopImage/${item.vendorImage1}`} className="card-img-top sportacademyCArd" alt="" />
                                        <div className="card-body text-center">
                                            <h5 className="card-title">{item.name}</h5>
                                            <p className="card-text">{item.about}</p>
                                            <Link to={`/Sports-Academy/${item.vendorURL}`}
                                                className="col-lg-6"
                                                key={item._id}
                                            >
                                                Check Service
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                {/* Vendor Form */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group my-4">
                            <label>Select Vendor</label>
                            <select
                                className="form-control"
                                value={selectedVendorId}
                                onChange={handleVendorSelect}
                            >
                                <option value="">Select a vendor</option>
                                {vendors.map((vendor) => (
                                    <option key={vendor._id} value={vendor._id}>
                                        {vendor.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="mb-5">
                        <h3>Edit Vendor</h3>
                        <form className="row" onSubmit={handleSubmit}>
                            <div className="col-md-2 mb-3">
                                <label>Vendor Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter vendor name"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label>About</label>
                                <input
                                    type="text"
                                    name="about"
                                    value={formData.about}
                                    onChange={handleInputChange}
                                    placeholder="About"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label>Vendor Profile Image</label>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="form-control"
                                    name="vendorImage1"
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label>Vendor URL</label>
                                <select
                                    name="vendorURL"
                                    value={formData.vendorURL}
                                    onChange={handleInputChange}
                                    className="form-control"
                                >
                                    <option value="">Select Defence Vendor</option>
                                    {categories.map((cat) => (
                                        <option key={cat._id} value={cat.title}>
                                            {`${cat._id} - ${cat.title}`} {/* Display the appropriate field for vendor title */}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="col-md-2 mb-3">
                                <label>Register Date:</label>
                                <input
                                    type="date"
                                    name="registerDate"
                                    value={formData.registerDate}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label>Expire Date:</label>
                                <input
                                    type="date"
                                    name="expireDate"
                                    value={formData.expireDate}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                            </div>

                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary">
                                    Update Vendor
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
