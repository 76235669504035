import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

function EditTrainer() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [profileImageTrainer, setProfileImageTrainer] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [experience, setExperience] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expireDate, setExpireDate] = useState("");

  // Function to fetch trainer data
  useEffect(() => {
    if (id) {
      axios.get(`/api/trainer/${id}`)
        .then((response) => {
          const data = response.data;
          setProfileImageTrainer(data.profileImageTrainer);
          setPhone(data.phone);
          setCategory(data.category);
          setAddress(data.address);
          setExperience(data.experience);
          setMapLocation(data.mapLocation);
          setStartDate(data.startDate);
          setExpireDate(data.expireDate);
        })
        .catch((error) => {
          console.error('Error fetching trainer data:', error);
        });
    }
  }, [id]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // FormData to handle both text and file fields
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("category", category);
    formData.append("address", address);
    formData.append("experience", experience);
    formData.append("mapLocation", mapLocation);
    formData.append("startDate", startDate);
    formData.append("expireDate", expireDate);

    // Append profileImageTrainer if it's a new file
    if (profileImageTrainer instanceof File) {
      formData.append("profileImageTrainer", profileImageTrainer);
    }

    // Send PUT request to update trainer data
    axios.put(`/api/trainer/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Trainer details updated successfully.",
          icon: "success",
        });
        navigate("/dashboard/total-trainers"); // Redirect to the trainers list
      })
      .catch((error) => {
        console.error("Error updating trainer:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to update trainer details.",
          icon: "error",
        });
      });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setProfileImageTrainer(e.target.files[0]);
  };

  // Handle closing the form without saving
  const handleCloseForm = () => {
    navigate("/dashboard/total-trainers"); // Redirect to the trainers list
  };

  return (
    <div className="container">
      <h3>Edit Trainer Details</h3>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="profileImageTrainer" className="form-label">
                Profile Image
              </label>
              <input
                type="file"
                name="profileImageTrainer"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                placeholder="Enter phone number"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="category" className="form-label">Category</label>
              <input
                type="text"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="form-control"
                placeholder="Enter category"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">Address</label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="Enter address"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="experience" className="form-label">Experience</label>
              <input
                type="text"
                name="experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                className="form-control"
                placeholder="Enter experience"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="mapLocation" className="form-label">Map Location</label>
              <input
                type="text"
                name="mapLocation"
                value={mapLocation}
                onChange={(e) => setMapLocation(e.target.value)}
                className="form-control"
                placeholder="Enter map location"
                required
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <h3>Duration</h3>
            <div className="mb-3">
              <label htmlFor="startDate" className="form-label">Start Date</label>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="expireDate" className="form-label">Expire Date</label>
              <input
                type="date"
                name="expireDate"
                value={expireDate}
                onChange={(e) => setExpireDate(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <button type="submit" className="btn btn-primary">
            Update Trainer
          </button>
          <button type="button" className="btn btn-secondary ms-2" onClick={handleCloseForm}>
            Close
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditTrainer;
