import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import DUMMYCITIES from '../../../components/dummydata.json'
import { Form } from 'react-bootstrap';

const EditUser = () => {
  const [vendorCat, setVendorCat] = useState([])
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategoryName] = useState("");
  const [businessUrl, setBusinessUrl] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [title, setTitle] = useState("");
  const [timings, setTimings] = useState("");
  const [yearsExperience, setYearsExperience] = useState("");
  const [about, setAbout] = useState("");
  const [catelogue1, setCatelogue1] = useState("");
  const [catelogue2, setCatelogue2] = useState("");
  const [catelogue3, setCatelogue3] = useState("");
  const [catelogue4, setCatelogue4] = useState("");
  const [catelogue5, setCatelogue5] = useState("");
  const [catelogue6, setCatelogue6] = useState("");
  const [catelogue7, setCatelogue7] = useState("");
  const [catelogue8, setCatelogue8] = useState("");
  const [catelogue9, setCatelogue9] = useState("");
  const [catelogue10, setCatelogue10] = useState("");
  const [catelogue11, setCatelogue11] = useState("");
  const [catelogue12, setCatelogue12] = useState("");
  const [heading1, setHeading1] = useState("");
  const [heading2, setHeading2] = useState("");
  const [heading3, setHeading3] = useState("");
  const [heading4, setHeading4] = useState("");
  const [heading5, setHeading5] = useState("");
  const [heading6, setHeading6] = useState("");
  const [heading7, setHeading7] = useState("");
  const [heading8, setHeading8] = useState("");
  const [heading9, setHeading9] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [heading10, setHeading10] = useState("");
  const [heading11, setHeading11] = useState("");
  const [heading12, setHeading12] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isPaid, setIsPaid] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [vendors, setVendors] = useState([])
  const [editUser, setEditUser] = useState(null);
  const maxWords = 500;
  const [wordCount, setWordCount] = useState(0);
  const [formData, setFormData] = useState();



  const { id } = useParams()
  const navigate = useNavigate()

  function getSingleVendorData() {
    axios.get(`/api/get_vender/byid/${id}`)
      .then((res) => {
        const data = res.data.data;
        setTitle(data.title);
        setEmail(data.email);
        setState(data.state);
        setCity(data.city);
        setCategoryName(data.category);
        setTimings(data.timings);
        setYearsExperience(data.yearsExperience);
        setAbout(data.about);
        setCatelogue1(data.catelogue1);
        setCatelogue2(data.catelogue2);
        setCatelogue3(data.catelogue3);
        setCatelogue4(data.catelogue4);
        setCatelogue5(data.catelogue5);
        setCatelogue6(data.catelogue6);
        setCatelogue7(data.catelogue7);
        setCatelogue8(data.catelogue8);
        setCatelogue9(data.catelogue9);
        setCatelogue10(data.catelogue10);
        setCatelogue11(data.catelogue11);
        setCatelogue12(data.catelogue12);
        setHeading1(data.heading1);
        setHeading2(data.heading2);
        setHeading3(data.heading3);
        setHeading4(data.heading4);
        setHeading5(data.heading5);
        setHeading6(data.heading6);
        setHeading7(data.heading7);
        setHeading8(data.heading8);
        setHeading9(data.heading9);
        setHeading10(data.heading10);
        setHeading11(data.heading11);
        setHeading12(data.heading12);
        setStartDate(data.startDate);
        setExpireDate(data.expireDate);
        setPhone(data.phone);
        setAddress(data.address);
        setIsPaid(data.isPaid);
        setMapLocation(data.mapLocation);
        setProfileUrl(data.profileUrl);
        setBannerImage(data.bannerImage);
        setBusinessUrl(data.businessUrl)
        // ... (other setState calls)
      })
      .catch((error) => {
        console.error('Error fetching single vendor:', error);
      });
  }
  const fetchCategories = () => {
    axios.get('/api/get-all-category')
      .then((response) => {
        // console.log(response.data); // Check the fetched data
        setVendorCat(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  const updateVendorHandler = async (e) => {
    e.preventDefault();

    // Create a FormData object to handle text and file inputs
    const formData = new FormData();

    // Append all text fields to FormData
    formData.append("state", state);
    formData.append("email", email);
    formData.append("city", city);
    formData.append("category", category);
    formData.append("title", title);
    formData.append("timings", timings);
    formData.append("yearsExperience", yearsExperience);
    formData.append("about", about);
    formData.append("heading1", heading1);
    formData.append("heading2", heading2);
    formData.append("heading3", heading3);
    formData.append("heading4", heading4);
    formData.append("heading5", heading5);
    formData.append("heading6", heading6);
    formData.append("heading7", heading7);
    formData.append("heading8", heading8);
    formData.append("heading9", heading9);
    formData.append("heading10", heading10);
    formData.append("heading11", heading11);
    formData.append("heading12", heading12);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("startDate", startDate);
    formData.append("expireDate", expireDate);
    formData.append("isPaid", isPaid || "Free"); // Default to "Free" if not provided
    formData.append("mapLocation", mapLocation || "");
    formData.append("businessUrl",businessUrl)

    // Append files only if a new file is selected for each field
    if (profileUrl instanceof File) {
      formData.append("profileUrl", profileUrl);
    }

    if (bannerImage instanceof File) {
      formData.append("bannerImage", bannerImage);
    }

    // For catelogue fields 1 to 12
    if (catelogue1 instanceof File) {
      formData.append("catelogue1", catelogue1);
    }
    if (catelogue2 instanceof File) {
      formData.append("catelogue2", catelogue2);
    }
    if (catelogue3 instanceof File) {
      formData.append("catelogue3", catelogue3);
    }
    if (catelogue4 instanceof File) {
      formData.append("catelogue4", catelogue4);
    }
    if (catelogue5 instanceof File) {
      formData.append("catelogue5", catelogue5);
    }
    if (catelogue6 instanceof File) {
      formData.append("catelogue6", catelogue6);
    }
    if (catelogue7 instanceof File) {
      formData.append("catelogue7", catelogue7);
    }
    if (catelogue8 instanceof File) {
      formData.append("catelogue8", catelogue8);
    }
    if (catelogue9 instanceof File) {
      formData.append("catelogue9", catelogue9);
    }
    if (catelogue10 instanceof File) {
      formData.append("catelogue10", catelogue10);
    }
    if (catelogue11 instanceof File) {
      formData.append("catelogue11", catelogue11);
    }
    if (catelogue12 instanceof File) {
      formData.append("catelogue12", catelogue12);
    }


    try {
      // Send FormData to the backend
      const response = await axios.put(`/api/edit_vender/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Response from backend:", response.data);

      // Reset input fields on success
      setState("");
      setCity("");
      setEmail("");
      setCategoryName("");
      setTitle("");
      setTimings("");
      setYearsExperience("");
      setAbout("");
      setHeading1("");
      setHeading2("");
      setHeading3("");
      setHeading4("");
      setHeading5("");
      setHeading6("");
      setHeading7("");
      setHeading8("");
      setHeading9("");
      setHeading10("");
      setHeading11("");
      setHeading12("");
      setPhone("");
      setAddress("");
      setIsPaid(false);
      setMapLocation("");
      setProfileUrl("");
      setBannerImage("");
      setCatelogue1("");
      setCatelogue2("");
      setCatelogue3("");
      setCatelogue4("");
      setCatelogue5("");
      setCatelogue6("");
      setCatelogue7("");
      setCatelogue8("");
      setCatelogue9("");
      setCatelogue10("");
      setCatelogue11("");
      setCatelogue12("");
      setStartDate("");
      setExpireDate("");
      setBusinessUrl("");

      // Success notification
      Swal.fire({
        title: "Success!",
        text: "Data has been updated successfully.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });

      // Navigate to a different page (e.g., dashboard)
      navigate("/dashboard/total-users");
    } catch (error) {
      console.error("Error updating vendor:", error);

      // Error notification
      Swal.fire({
        title: "Error!",
        text: "Failed to update data. Please try again.",
        icon: "error",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const words = value.trim().split(/\s+/).filter(word => word !== "");
    if (words.length <= maxWords) {
      setFormData({ ...formData, [name]: value });
      setWordCount(words.length);
    } else {
      alert("You can enter max 500 words");
    }
  };


  useEffect(() => {
    getSingleVendorData()
    fetchCategories()
  }, [id])

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity(''); // Reset city when state changes
  };
  
  useEffect(() => {
    const foundUser = vendors.find((vendor) => vendor._id === id);
    if (foundUser) {
      setEditUser(foundUser);
      setCategoryName(foundUser.category); // Set the initial category
    }
  }, [id, vendors]);
  const isUserLoggedIn = localStorage.getItem("user");
  const scond = JSON.parse(isUserLoggedIn)

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar">
          <h4>Edit Vendor
          </h4>
        </div>
        {scond.data.userrollid === "admin" ?
          <form action="/upload" method="POST" enctype="multipart/form-data" onSubmit={updateVendorHandler}>
            <div className="row">
              {/* Left Column */}
              <div className="col-md-4">
              <div className="mb-3">
                  <label htmlFor="isPaid" className="form-label">
                    Paid or Free
                  </label>
                  <select
                    className="form-control"
                    id="isPaid"
                    value={isPaid}
                    onChange={(e) => setIsPaid(e.target.value)}
                    name="isPaid"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Paid">Paid</option>
                    <option value="Free">Free</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Business Name
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="bannerImage" className="form-label">
                    Upload Banner Image (height:250px ,width:1300px)
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setBannerImage(e.target.files[0])}
                    id="bannerImage"
                    accept="image/*" // Restrict to images
                    name="bannerImage"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="profileUrl" className="form-label">
                    Upload Profile Image
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setProfileUrl(e.target.files[0])}
                    className="form-control"
                    id="profileUrl"
                    name="profileUrl"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">
                    Category Name
                  </label>
                  <select
                    className="form-control"
                    id="categoryName"
                    value={category}
                    onChange={(e) => setCategoryName(e.target.value)}
                    name="category"
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {vendorCat.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="timings" className="form-label">
                    Timing (Timing format must be like- 10AM to 7PM)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="timings"
                    value={timings}
                    onChange={(e) => setTimings(e.target.value)}
                    name="timings"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="state" className="form-label">State</label>
                  <select
                    id="state"
                    className="form-control"
                    value={state}
                    onChange={handleStateChange}
                  >
                    <option value="">Select State</option>
                    {Object.keys(DUMMYCITIES).map((stateName) => (
                      <option key={stateName} value={stateName}>
                        {stateName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* City Select */}
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">City</label>
                  <select
                    id="city"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={!state}
                  >
                    <option value="">Select City</option>
                    {state && DUMMYCITIES[state].map((cityName) => (
                      <option key={cityName} value={cityName}>
                        {cityName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="about" className="form-label">
                    About (Maximum {maxWords} words) <small className="ms-3 form-text text-muted">
                      {wordCount} / {maxWords} words
                    </small>
                  </label>
                  <textarea
                    className="form-control"
                    id="about"
                    rows="5"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    name="about"
                  />

                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    id="address"
                    rows="3"
                    cols="5"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="yearsExperience" className="form-label">
                    Years of Experience
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="yearsExperience"
                    value={yearsExperience}
                    onChange={(e) => setYearsExperience(e.target.value)}
                    name="yearsExperience"

                  />
                </div>
                <div className="mb-3">
                <label className="form-label">Register Date</label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Expire Date</label>
                <input
                  type="date"
                  name="expireDate"
                  id="expireDate"
                  value={expireDate}
                  onChange={(e) => setExpireDate(e.target.value)}
                  className="form-control"
                />
              </div>

              </div>

              {/* Middle Column */}

              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="mapLocation" className="form-label">
                    Map Location
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mapLocation"
                    value={mapLocation}
                    onChange={(e) => setMapLocation(e.target.value)}
                    name="mapLocation"

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Youtube Video Link(embed)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="businessUrl"
                    value={businessUrl}
                    onChange={(e) => setBusinessUrl(e.target.value)}
                    name="businessUrl"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirmPassword"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 1
                  </label>
                  <input
                    type="file"
                    name="catelogue1"
                    onChange={(e) => setCatelogue1(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading1" className="form-label">
                    Catalogue Heading 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading1"
                    value={heading1}
                    onChange={(e) => setHeading1(e.target.value)}
                    name="heading1"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 2
                  </label>
                  <input
                    type="file"
                    name="catelogue2"
                    onChange={(e) => setCatelogue2(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading2" className="form-label">
                    Catalogue Heading 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading2"
                    value={heading2}
                    onChange={(e) => setHeading2(e.target.value)}
                    name="heading2"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 3
                  </label>
                  <input
                    type="file"
                    name="catelogue3"
                    onChange={(e) => setCatelogue3(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading3" className="form-label">
                    Catalogue Heading 3
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading3"
                    value={heading3}
                    onChange={(e) => setHeading3(e.target.value)}
                    name="heading3"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 4
                  </label>
                  <input
                    type="file"
                    name="catelogue4"
                    onChange={(e) => setCatelogue4(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading4" className="form-label">
                    Catalogue Heading 4
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading4"
                    value={heading4}
                    onChange={(e) => setHeading4(e.target.value)}
                    name="heading4"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 5
                  </label>
                  <input
                    type="file"
                    name="catelogue5"
                    onChange={(e) => setCatelogue5(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading5" className="form-label">
                    Catalogue Heading 5
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading5"
                    value={heading5}
                    onChange={(e) => setHeading5(e.target.value)}
                    name="heading5"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 6
                  </label>
                  <input
                    type="file"
                    name="catelogue6"
                    onChange={(e) => setCatelogue6(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading6" className="form-label">
                    Catalogue Heading 6
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading6"
                    value={heading6}
                    onChange={(e) => setHeading6(e.target.value)}
                    name="heading6"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 7
                  </label>
                  <input
                    type="file"
                    name="catelogue7"
                    onChange={(e) => setCatelogue7(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading7" className="form-label">
                    Catalogue Heading 7
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading7"
                    value={heading7}
                    onChange={(e) => setHeading7(e.target.value)}
                    name="heading7"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 8
                  </label>
                  <input
                    type="file"
                    name="catelogue8"
                    onChange={(e) => setCatelogue8(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading8" className="form-label">
                    Catalogue Heading 8
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading8"
                    value={heading8}
                    onChange={(e) => setHeading8(e.target.value)}
                    name="heading8"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 9
                  </label>
                  <input
                    type="file"
                    name="catelogue9"
                    onChange={(e) => setCatelogue9(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading9" className="form-label">
                    Catalogue Heading 9
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading9"
                    value={heading9}
                    onChange={(e) => setHeading9(e.target.value)}
                    name="heading9"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 10
                  </label>
                  <input
                    type="file"
                    name="catelogue10"
                    onChange={(e) => setCatelogue10(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading10" className="form-label">
                    Catalogue Heading 10
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading10"
                    value={heading10}
                    onChange={(e) => setHeading10(e.target.value)}
                    name="heading10"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 11
                  </label>
                  <input
                    type="file"
                    name="catelogue11"
                    onChange={(e) => setCatelogue11(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading11" className="form-label">
                    Catalogue Heading 11
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading11"
                    value={heading11}
                    onChange={(e) => setHeading11(e.target.value)}
                    name="heading11"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 12
                  </label>
                  <input
                    type="file"
                    name="catelogue12"
                    onChange={(e) => setCatelogue12(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading12" className="form-label">
                    Catalogue Heading 12
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading12"
                    value={heading12}
                    onChange={(e) => setHeading12(e.target.value)}
                    name="heading12"
                  />
                </div>
              </div>
            </div>

            {/* onClick={handleUpload} */}
            <button type="submit" className="btn btn-success">
              Add User
            </button>
          </form>

          // user dashboard 

          :
          
          <form action="/upload" method="POST" enctype="multipart/form-data" onSubmit={updateVendorHandler}>
            <div className="row">
              {/* Left Column */}
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="bannerImage" className="form-label">
                    Upload Banner Image (h:250px ,w:1300px)
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setBannerImage(e.target.files[0])}
                    id="bannerImage"
                    accept="image/*" // Restrict to images
                    name="bannerImage"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="profileUrl" className="form-label">
                    Upload Profile Image
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setProfileUrl(e.target.files[0])}
                    className="form-control"
                    id="profileUrl"
                    name="profileUrl"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">
                    Category Name
                  </label>
                  <select
                    className="form-control"
                    id="categoryName"
                    value={category}
                    onChange={(e) => setCategoryName(e.target.value)}
                    name="category"
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {vendorCat.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="timings" className="form-label">
                    Timing (Timing format must be like- 10AM to 7PM)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="timings"
                    value={timings}
                    onChange={(e) => setTimings(e.target.value)}
                    name="timings"

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="state" className="form-label">State</label>
                  <select
                    id="state"
                    className="form-control"
                    value={state}
                    onChange={handleStateChange}
                  >
                    <option value="">Select State</option>
                    {Object.keys(DUMMYCITIES).map((stateName) => (
                      <option key={stateName} value={stateName}>
                        {stateName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* City Select */}
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">City</label>
                  <select
                    id="city"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={!state}
                  >
                    <option value="">Select City</option>
                    {state && DUMMYCITIES[state].map((cityName) => (
                      <option key={cityName} value={cityName}>
                        {cityName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="about" className="form-label">
                    About (Maximum {maxWords} words) <small className="ms-3 form-text text-muted">
                      {wordCount} / {maxWords} words
                    </small>
                  </label>
                  <textarea
                    className="form-control"
                    id="about"
                    rows="5"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    name="about"
                  />

                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    id="address"
                    rows="3"
                    cols="5"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="yearsExperience" className="form-label">
                    Years of Experience
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="yearsExperience"
                    value={yearsExperience}
                    onChange={(e) => setYearsExperience(e.target.value)}
                    name="yearsExperience"

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mapLocation" className="form-label">
                    Map Location
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mapLocation"
                    value={mapLocation}
                    onChange={(e) => setMapLocation(e.target.value)}
                    name="mapLocation"
                  />
                </div>
              </div>

              <div className="col-md-4">
                
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Youtube Video Link(embed)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="businessUrl"
                    value={businessUrl}
                    onChange={(e) => setBusinessUrl(e.target.value)}
                    name="businessUrl"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 1
                  </label>
                  <input
                    type="file"
                    name="catelogue1"
                    onChange={(e) => setCatelogue1(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading1" className="form-label">
                    Catalogue Heading 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading1"
                    value={heading1}
                    onChange={(e) => setHeading1(e.target.value)}
                    name="heading1"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 2
                  </label>
                  <input
                    type="file"
                    name="catelogue2"
                    onChange={(e) => setCatelogue2(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading2" className="form-label">
                    Catalogue Heading 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading2"
                    value={heading2}
                    onChange={(e) => setHeading2(e.target.value)}
                    name="heading2"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 3
                  </label>
                  <input
                    type="file"
                    name="catelogue3"
                    onChange={(e) => setCatelogue3(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading3" className="form-label">
                    Catalogue Heading 3
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading3"
                    value={heading3}
                    onChange={(e) => setHeading3(e.target.value)}
                    name="heading3"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 4
                  </label>
                  <input
                    type="file"
                    name="catelogue4"
                    onChange={(e) => setCatelogue4(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading4" className="form-label">
                    Catalogue Heading 4
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading4"
                    value={heading4}
                    onChange={(e) => setHeading4(e.target.value)}
                    name="heading4"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 5
                  </label>
                  <input
                    type="file"
                    name="catelogue5"
                    onChange={(e) => setCatelogue5(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading5" className="form-label">
                    Catalogue Heading 5
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading5"
                    value={heading5}
                    onChange={(e) => setHeading5(e.target.value)}
                    name="heading5"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 6
                  </label>
                  <input
                    type="file"
                    name="catelogue6"
                    onChange={(e) => setCatelogue6(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading6" className="form-label">
                    Catalogue Heading 6
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading6"
                    value={heading6}
                    onChange={(e) => setHeading6(e.target.value)}
                    name="heading6"
                  />
                </div>
              </div>
              <div className="col-md-4">


                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 7
                  </label>
                  <input
                    type="file"
                    name="catelogue7"
                    onChange={(e) => setCatelogue7(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading7" className="form-label">
                    Catalogue Heading 7
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading7"
                    value={heading7}
                    onChange={(e) => setHeading7(e.target.value)}
                    name="heading7"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 8
                  </label>
                  <input
                    type="file"
                    name="catelogue8"
                    onChange={(e) => setCatelogue8(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading8" className="form-label">
                    Catalogue Heading 8
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading8"
                    value={heading8}
                    onChange={(e) => setHeading8(e.target.value)}
                    name="heading8"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 9
                  </label>
                  <input
                    type="file"
                    name="catelogue9"
                    onChange={(e) => setCatelogue9(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading9" className="form-label">
                    Catalogue Heading 9
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading9"
                    value={heading9}
                    onChange={(e) => setHeading9(e.target.value)}
                    name="heading9"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 10
                  </label>
                  <input
                    type="file"
                    name="catelogue10"
                    onChange={(e) => setCatelogue10(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading10" className="form-label">
                    Catalogue Heading 10
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading10"
                    value={heading10}
                    onChange={(e) => setHeading10(e.target.value)}
                    name="heading10"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 11
                  </label>
                  <input
                    type="file"
                    name="catelogue11"
                    onChange={(e) => setCatelogue11(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading11" className="form-label">
                    Catalogue Heading 11
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading11"
                    value={heading11}
                    onChange={(e) => setHeading11(e.target.value)}
                    name="heading11"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images 12
                  </label>
                  <input
                    type="file"
                    name="catelogue12"
                    onChange={(e) => setCatelogue12(e.target.files[0])}
                    className="form-control"
                    id="catalogueImages"
                    accept="image/*"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heading12" className="form-label">
                    Catalogue Heading 12
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading12"
                    value={heading12}
                    onChange={(e) => setHeading12(e.target.value)}
                    name="heading12"
                  />
                </div>
              </div>
            </div>

            {/* onClick={handleUpload} */}
            <button type="submit" className="btn btn-success">
              Add User
            </button>
          </form>
        }
      </div >
    </main >
  );
};

export default EditUser;
