import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function EditCategory() {
  const { id } = useParams(); // Category ID from URL
  const navigate = useNavigate();

  const [category, setCategoryName] = useState(""); // State to store the category name
  const [currentCategoryIcon, setCurrentCategoryIcon] = useState(""); // Current image URL
  const [categoryIcon, setCategoryIcon] = useState(null); // New image file

  // Fetch the category data by ID
  const getSingleCategoryData = async () => {
    try {
      const res = await axios.get(`/api/get_category/${id}`);
      setCategoryName(res.data.category);
      setCurrentCategoryIcon(res.data.categoryIcon); // Set current image URL
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };


  const updateCategoryHandler = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("category", category); // Append category text
  
    // Append categoryIcon if a new file is selected
    if (categoryIcon) {
      formData.append("categoryIcon", categoryIcon);
    }
  
    try {
      const response = await axios.put(
        `/api/edit_category/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Category updated:", response.data);
      navigate("/dashboard/total-category"); // Redirect after successful update
    } catch (error) {
      console.error("Error updating category:", error.response?.data || error.message);
    }
  };
  

  // Fetch category data on component mount
  useEffect(() => {
    getSingleCategoryData();
  }, [id]);

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar">
          <h4>Edit Category</h4>
        </div>
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={updateCategoryHandler}>
              {/* Category Title */}
              <div className="mb-3">
                <label htmlFor="category" className="form-label">Category Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  name="category"
                  value={category}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </div>

              {/* Current Category Icon Preview */}
              {/* <div className="mb-3">
                <label className="form-label">Current Category Icon</label>
                {currentCategoryIcon && (
                  <div>
                    <img
                      src={`/${currentCategoryIcon}`}
                      alt="Current Icon"
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                  </div>
                )}
              </div> */}

              {/* Upload New Category Icon */}
              <div className="mb-3">
                <label htmlFor="categoryIcon" className="form-label">Upload New Category Icon</label>
                <input
                  type="file"
                  className="form-control"
                  id="categoryIcon"
                  name="categoryIcon"
                  accept="image/*"
                  onChange={(e) => setCategoryIcon(e.target.files[0])}
                />
              </div>

              <button type="submit" className="btn btn-success">Update Category</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EditCategory;
