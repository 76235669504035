import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import Swal from 'sweetalert2';
import axios from 'axios';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

const TotalCategory = () => {
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchCategories();
    }, []);

    // Fetch data from the API
    const fetchCategories = () => {
        axios.get('/api/get-all-category')
            .then((response) => {
                const sortedCategory = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setCategories(sortedCategory);
                console.log(response);


            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };

    // Delete a category
    const deleteHandleUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`/api/delete_category/${id}`)
                    .then((res) => {
                        setCategories((prevCategories) =>
                            prevCategories.filter((category) => category._id !== id)
                        );
                        Swal.fire("Deleted!", "Category has been deleted.", "success");
                    })
                    .catch((error) => {
                        console.error("Error deleting category:", error);
                        Swal.fire(
                            "Error!",
                            "There was an error deleting the category.",
                            "error"
                        );
                    });
            }
        });
    };

    // Update DataTable when categories change
    useEffect(() => {
        if (categories.length > 0) {
            const table = $('#example').DataTable();
            return () => table.destroy(); // Cleanup DataTable on unmount or re-render
        }
    }, [categories]);

    const filteredCategories = categories.filter(category =>
        category.category?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <main className="content px-3 py-2">
            <div className="container">
                <div className="title-bar1">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4">
                            <h4>Category Management</h4>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Categories List</li>
                            </ol>
                        </div>


                        <div className="col-md-6">
                            <div className="totle-use">
                                <h4>Total Categories: <b>{filteredCategories.length}</b></h4>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <Link to="/dashboard/add-category" className="btn btn-sm py-2 m-0 btn-success" style={{ lineHeight: "27px" }}>
                                <IoIosAddCircleOutline /> Add Category
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table id="example" className="display table table-bordered">
                            <thead className=" table-light">
                                <tr>
                                    <th>ID</th>
                                    <th>Category Icon</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCategories.length > 0 ? filteredCategories.map((category, idx) => (
                                    <tr key={category._id}>
                                        <td className='w-10'>{idx + 1}</td>
                                        <td className='w-50'>
                                            {/* Show the image if categoryIcon is present */}
                                            {category.categoryIcon ? (
                                                <img
                                                    src={`https://api.khiladiindia.com/backend/uploads/categoryIcons/${category.categoryIcon}`}
                                                    alt={category.category}
                                                    width="50"
                                                    height="50"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            ) : (
                                                "No icon available"
                                            )}
                                        </td>
                                        <td className='w-50'>{category.category}</td>

                                        <td className='w-50'>
                                            <Link to={`/dashboard/edit-category/${category._id}`} className='btn  btn-sm btn-primary me-2'>
                                                <CiEdit /> Edit
                                            </Link>
                                            <button
                                                type="button"
                                                className='btn btn-sm btn-danger'
                                                onClick={() => deleteHandleUser(category._id)}
                                            >
                                                <MdDeleteOutline /> Delete
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">No categories found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </main >
    );
};

export default TotalCategory;
