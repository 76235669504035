import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";

const JobApproval = () => {
  const [jobs, setJobs] = useState([]);
  const [venders, setVenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch job approval requests from the server
  const fetchRequests = () => {
    axios
      .get("/api/get-job-posts") // Replace with your actual API endpoint
      .then((response) => {
       
        setJobs(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching requests:", err);
        setError("Failed to fetch job approval requests.");
        setLoading(false);
      });
  };

  const fetchVendors = () => {
    axios
      .get("/api/get_vender") // Replace with your actual API endpoint
      .then((response) => {
       
        setVenders(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching requests:", err);
        setError("Failed to fetch job approval requests.");
        setLoading(false);
      });
  };

  // Handle approval of a request
  const handleApprove = (id) => {
    axios
      .put(`/api/approve-job/${id}`)
      .then(() => {
        Swal.fire("Approved!", "The job has been approved.", "success");
        fetchRequests();
      })
      .catch((err) => {
        console.error("Error approving job:", err);
        Swal.fire("Error", "Failed to approve the job.", "error");
      });
  };

  // Handle rejection of a request
  const handleReject = (id) => {
    axios
      .put(`/api/reject/${id}`)
      .then(() => {
        Swal.fire("Rejected!", "The job has been rejected.", "info");
        fetchRequests();
      })
      .catch((err) => {
        console.error("Error rejecting job:", err);
        Swal.fire("Error", "Failed to reject the job.", "error");
      });
  };

  const deleteHandleUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/delete-job/${id}`)
          .then(() => {
            window.location.reload();
            Swal.fire("Deleted!", "User has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            Swal.fire("Error!", "There was an error deleting the user.", "error");
          });
      }
    });
  };

  useEffect(() => {
    fetchRequests();
    fetchVendors()
  }, []);


  const filteredJobs = jobs.filter((job) => {
    const vendor = venders.find((v) => v._id === job.vendorId); 
// Match vendor with job
    return vendor && vendor.activation === "Active";
  });


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

 

  return (
    <div className="container py-4">
      <h2 className="mb-4">Job Approval Requests</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Job title Name</th>
            <th>Job Category</th>
            <th>jobType</th>
            <th>City</th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((request, idx) => (
              <tr key={request._id}>
                <td>{idx}</td>
                <td>{request.jobTitle}</td>
                <td>{request.jobTitle}</td>
                <td>{request.jobType}</td>
                <td>{request.city}</td>
                <td>
                  {request.jobApproval === true ? <Button> Approved</Button> :
                    <Button variant="warning" onClick={() => handleApprove(request._id)}>Approve</Button>}
                  <button
                    className="btn btn-danger btn-sm ms-3"
                    onClick={() => handleReject(request._id)}
                  >
                    Reject
                  </button>
                  <Link
                    to={`/dashboard/job-edit/${request._id}`}
                    className="btn btn-primary mx-2 btn-sm"
                  >
                    <CiEdit /> Edit
                  </Link>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteHandleUser(request._id)}
                  >
                    <MdDeleteOutline /> Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No job approval requests found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default JobApproval;
