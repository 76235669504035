import React, { useEffect, useState } from "react";
import axios from "axios";
// import jobListingArrow from "../../../assets/images/desktopArrow.svg";
import callIcon from "../../../assets/images/desktopArrow.svg"; // Replace with the actual path to your call icon image

const JobsListing = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // Filter state variables
    const [experienceFilter, setExperienceFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");

    // Fetch jobs from the API
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                setLoading(true);
                const response = await axios.get("/api/get-job-posts"); // Replace with your API endpoint
                setJobs(response.data); // Assuming the API returns an array of jobs
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch job posts. Please try again.");
                setLoading(false);
            }
        };
        fetchJobs();
    }, []);

    // Extract unique categories and experience levels from the jobs data
    const uniqueCategories = [...new Set(jobs.map((job) => job.jobCategory))];
    const uniqueExperience = [...new Set(jobs.map((job) => job.minExp))];

    // Filter jobs based on the selected filters
    const filteredJobs = jobs.filter((job) => {
        const matchesExperience =
            experienceFilter === "" || job.minExp === experienceFilter;
        const matchesCategory =
            categoryFilter === "" || job.jobCategory === categoryFilter;
        const matchesLocation =
            locationFilter === "" || job.city.toLowerCase().includes(locationFilter.toLowerCase());
        return matchesExperience && matchesCategory && matchesLocation;
    });

    if (loading) return <p className="loading">Loading job posts...</p>;
    if (error) return <p className="error">{error}</p>;

    const aprovedjob = filteredJobs.filter(item => item.jobApproval === true)
    console.log(aprovedjob)

    return (
        <div className="jobsListing-container row">
            <div className="position-relative col-md-3">
                <div className="position-relative h-100">
                    <div className="filter-container position-sticky">
                        <label>
                            Experience:
                            <select
                                value={experienceFilter}
                                onChange={(e) => setExperienceFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                {uniqueExperience.map((exp) => (
                                    <option key={exp} value={exp}>
                                        {exp}
                                    </option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Category:
                            <select
                                value={categoryFilter}
                                onChange={(e) => setCategoryFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                {uniqueCategories.map((category) => (
                                    <option key={category} value={category}>
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </label>

                        <label>
                            District:
                            <input
                                type="text"
                                placeholder="Enter city"
                                value={locationFilter}
                                onChange={(e) => setLocationFilter(e.target.value)}
                            />
                        </label>
                    </div>
                </div>

            </div>
            <div className="jobCards-container col-md-9">
                {aprovedjob.length > 0 ? (
                    aprovedjob.map((job) => (
                        <div key={job.id} className="jobCard">
                            {job.city && <span className="jobCard-badge">★ Top Match</span>}
                            <div className="d-flex row">
                                <div className="jobCard-header mt-3  col-md-6">
                                    <div className="jobCard-body">
                                        <div className="jobCard-details">
                                            <h2 className="jobCard-title">{job.jobTitle}</h2>
                                            <p className="jobCard-salary ">₹ {job.minSalary} - {job.maxSalary}/month
                                            </p>
                                            <p className="jobCard-location">📍 {job.city}</p>
                                            <p className="jobCard-company">{job.locality}</p>

                                            <div className="jobCard-tags">
                                                <span className="minExpReq"> Minimum Exp :</span>

                                                <span className="jobCard-tag vacancy"> {job.minExp}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jobCard-footer col-md-6">
                                    <p className="jobCard-company1">Qualification Required : {job.qualification}</p>
                                    <p className="jobCard-benefits">
                                        {job.benefits && job.benefits.length > 0
                                            ? `Benefits: ${job.benefits.join(", ")}`
                                            : ""}
                                    </p>

                                    <div className="jobCard-extra ">
                                        <a href={`tel:${job.mobNumber}`} className="jobCard-call">
                                            <img src={callIcon} alt="Call" />
                                        </a>
                                        <p className="jobCard-company">Address: {job.locality}</p>
                                        <p className="jobCard-timestamp text-black">{job.jobType}</p>
                                        <p className="jobCard-timestamp text-black">{job.createAt}</p>
                                    </div>
                                    {job._id && (
                                        <div className="jobCard-verified">
                                            <img
                                                src="https://img.icons8.com/fluency/15/null/verified-badge.png"
                                                alt="Verified"
                                                className="jobCard-verifiedIcon"
                                            />
                                            <span>Verified <b>Safe Job Hai</b></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-jobs">No jobs available for the selected filters.</p>
                )}
            </div>

        </div>
    );
};

export default JobsListing;
