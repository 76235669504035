import React, { useState } from 'react'
import cardImage from ".././assets/card_img.png";
import { useNavigate } from 'react-router-dom';

function TrainerRegistration() {

  const navigate = useNavigate();

  const handlePayment = (e) => {
    e.preventDefault();

    const options = {
      key: "rzp_live_Vo7iBIe6tuHy1Q,CxEt6qvliGxCPf3Q8JYckpWh", // Replace with your Razorpay key
      amount: 100000, // Amount in paise (e.g., 50000 paise = 500 INR)
      currency: "INR",
      name: "Khiladi India",
      description: "Trainer Registration Fee",
      image: "https://khiladiindia.com/logo.png", // Replace with your logo
      handler: function (response) {
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        document.body.style.overflow = "auto"; // Restore scrolling
      },
      prefill: {
        name: "John Doe", // Replace with actual user name
        email: "johndoe@example.com", // Replace with actual user email
        contact: "9999999999", // Replace with actual user contact number
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleCloseForm = () => {
    navigate("/");
  };

  return (
    <div className='container'>
      <div className="checkout-modal-overlay" onClick={handleCloseForm}></div>
      <div className="checkout-form-wrapper">
        <form className="checkout-form" onSubmit={handlePayment}>
          <div className="row">
            {/* Billing Address Section */}
            <div className="col-12 col-md-6">
              <h3 className="checkout-title">Billing Address</h3>
              <div className="checkout-inputBox">
                <span>Full Name:</span>
                <input type="text" placeholder="Enter Full Name " required />
              </div>
              <div className="checkout-inputBox">
                <span>Email:</span>
                <input type="email" placeholder="Enter email Id" required />
              </div>
              <div className="checkout-inputBox">
                <span>Address:</span>
                <input type="text" placeholder="Enter address" required />
              </div>
              <div className="checkout-inputBox">
                <span>City:</span>
                <input type="text" placeholder="Enter City" required />
              </div>
              <div className="checkout-flex">
                <div className="checkout-inputBox">
                  <span>State:</span>
                  <input type="text" placeholder="Enter State" required />
                </div>
                <div className="checkout-inputBox">
                  <span>Zip Code:</span>
                  <input type="text" placeholder="PinCode" required />
                </div>
              </div>
            </div>

            {/* Payment Section */}
            <div className="col-12 col-md-6">
              <h3 className="checkout-title">Payment</h3>
              <div className="checkout-inputBox">
                <span>Cards Accepted:</span>
                <img className="imgCard" src={cardImage} alt="Accepted Cards" />
              </div>
              <div className="checkout-inputBox">
                <span>Name on Card:</span>
                <input type="text" placeholder="Mr. Monty Thakur" />
              </div>
              <div className="checkout-inputBox">
                <span>Credit Card Number:</span>
                <input type="number" placeholder="1111-2222-3333-4444" />
              </div>
              <div className="checkout-inputBox">
                <span>Exp Month:</span>
                <input type="text" placeholder="January" />
              </div>
              <div className="checkout-flex">
                <div className="checkout-inputBox">
                  <span>Exp Year:</span>
                  <input type="number" placeholder="2022" />
                </div>
                <div className="checkout-inputBox">
                  <span>CVV:</span>
                  <input type="text" placeholder="1234" />
                </div>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="form-actions mt-4">
            <input
              type="submit"
              value="Proceed to Checkout"
              className="checkout-submit-btn btn btn-primary"
            />
            <button
              type="button"
              className="checkout-close-btn btn btn-secondary"
              onClick={handleCloseForm}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TrainerRegistration;
