import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUsers } from "react-icons/fa";

function DashboardHome() {
    const [venderUsers, setVenderUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [trainers, setTrainers] = useState([]);
    const isUserLoggedIn = localStorage.getItem("user");
    const scond = JSON.parse(isUserLoggedIn)

    const fetchUsers = () => {
        axios.get('/api/get_vender/')
            .then((response) => {
                setVenderUsers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    };

    const fetchCategories = () => {
        axios.get('/api/get-all-category')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };

    const fetchTrainers = () => {
        axios.get('/api/trainer')
            .then((response) => {
                setTrainers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };



    useEffect(() => {
        fetchUsers();
        fetchCategories();
        fetchTrainers();
    }, []);

    return (
        <main className="content px-3 py-2">


            {scond.data.userrollid === "admin" ? <div className="container-fluid">
                <div className="title-bar1">
                    <h4>Hi , Welcome to your  {scond.data.userrollid ? `admin panel` : `Vendor panal`}</h4>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Project Dashboard</li>
                    </ol>
                </div>
                <div className="">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card bg-gradient-start-1">
                                <div className="card-body">
                                    <Link to="/dashboard/total-users">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                            <div>
                                                <p className="fw-medium text-primary-light mb-1">Total Venders</p>
                                                <h6 className="mb-0"><b>{venderUsers.length}</b></h6>
                                            </div>
                                            <div className="icon-bar">
                                                <FaUsers />
                                            </div>
                                        </div>
                                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                            <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                                <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                            </span>
                                            Last 30 days users
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-gradient-start-1">
                                <div className="card-body">
                                    <Link to="/dashboard/total-category">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                            <div>
                                                <p className="fw-medium text-primary-light mb-1">Total Categories</p>
                                                <h6 className="mb-0"><b>{categories.length}</b></h6>
                                            </div>
                                            <div className="icon-bar">
                                                <FaUsers />
                                            </div>
                                        </div>
                                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                            <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                                <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                            </span>
                                            Last 30 days users
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-gradient-start-1">
                                <div className="card-body">
                                    <Link to="/dashboard">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                            <div>
                                                <p className="fw-medium text-primary-light mb-1">Total Trainers</p>
                                                <h6 className="mb-0"><b>{trainers.length}</b></h6>
                                            </div>
                                            <div className="icon-bar">
                                                <FaUsers />
                                            </div>
                                        </div>
                                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                            <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                                <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                            </span>
                                            Last 30 days users
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="user details">

                    </div>
                </div>


            </div>
             : 
             <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card bg-gradient-start-1">
                            <div className="card-body">
                                {/* <Link to="/dashboard"> */}
                                    <div className="d-flex flex-wrap align-items-start gap-3">
                                        <div>
                                            {/* <p className="fw-medium text-start text-primary-light mb-1"></p> */}
                                            <h6 className="mb-0 text-start fs-5"><b>Welcome To Your Dashboard Page </b></h6>
                                        </div>
                                        
                                    </div>
                                    <p className="fw-medium text-sm text-primary-light mt-3 mb-0 d-flex align-items-center gap-2">
                                    <div className="icon-bar">
                                            <FaUsers />
                                        </div>
                                        <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                            <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                        </span>
                                        Last 30 days users
                                    </p>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }




        </main>
    )
}

export default DashboardHome
