import React, { useEffect, useState } from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import p1 from "../assets/img_1.jpg";
import p2 from "../assets/img_2.jpg";
import p3 from "../assets/img_3.jpg";
import p4 from "../assets/img_4.jpg";
import p5 from "../assets/img_5.jpg";
import { Link } from 'react-router-dom';
// import 'animate.css';
import '../components/animation-library/css/zoom.css'
import axios from 'axios';
import VENDERUSER from '../VENDERUSER.json';

export default function DeffenceTrainingCe() {
  // Data for exercises

    const [searchQuery, setSearchQuery] = useState('');
    const [vendors, setVendors] = useState([]);




const fetchVendors = async () => {
  try {
    const response = await axios.get("/api/defencevenders");
    setVendors(response.data || []);
  } catch (error) {
    console.error("Error fetching vendors:", error.message);
  }
};

  useEffect(() => {
    fetchVendors();
  }, []);


  return (
    <div className="site-section" id="classes-section">
      <div className="site-section1" id="classes-section1">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8 section-heading">
              <h2 className="heading">Defence Training Center</h2>
            </div>
          </div>
          <div className="row u--zoomIn">
            {vendors.map((vendor, index) => (
              <Link to={`/Deffence-Center/${vendor.vendorURL}`} className="col-lg-6" key={vendor._id}>
                <div className="class-item d-flex align-items-center">
                  <div className="class-item-thumbnail">
                    <img src={`https://api.khiladiindia.com/backend/uploads/homepage/${vendor.profileImage }`} alt={vendor.vendorName} />
                  </div>
                  <div className="class-item-text">
                    <h2 className='text-white'>{vendor.vendorName}</h2>
                    <span>{vendor.about}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <Link to="/Deffence-Center" className="more-trainers-link">More Trainers</Link>
        </div>
      </div>
    </div>
  );
}
