import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DUMMYCITIES from './dummydata.json';

export default function ContactForm() {
    const [vendorCat, setVendorCat] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        city: '',
        gender: '',
        category: '',
        payment: '',
    });

    // Handle form input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const fetchCategories = () => {
        axios
            .get('/api/get-all-category')
            .then((response) => {
                setVendorCat(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const whatsappMessage = `Name: ${formData.name}\nEmail: ${formData.email}\nMobile: ${formData.mobile}\nCity: ${formData.city}\nGender: ${formData.gender}\nCategory: ${formData.category}\nPayment Type: ${formData.payment}`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=8279848804&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };

    const sortedCities = Object.values(DUMMYCITIES)
        .flat()
        .map((city) => city.trim())
        .sort((a, b) => a.localeCompare(b));

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div>
            <section id="contact" className="contact main-heading team p-5">
                <div className="container-fluid main-contact-from">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="contact-info">
                                <div className="kode-section-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <div className="kode-forminfo">
                                    <p>
                                        If you have any queries, feel free to reach out to us by filling in the contact form. We are here to assist with any inquiries related to sports academies, events, services, and more. Our team will get back to you promptly based on the details provided.
                                    </p>
                                    <ul className="kode-form-list">
                                        <li>
                                            <i className="fa fa-home"></i>
                                            <strong>Address:</strong>
                                            <p>
                                                Khiladi India office near Krishna Sagar Hotel, Gangneher Muradnagar, near Krishna Sagar Hotel, Ghaziabad, Uttar Pradesh 201206
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <strong>Phone:</strong>
                                            <p>+91-827 984 8804</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-5">
                            <div className="contact-us">
                                <form method="post" className="comments-form" id="contactform" onSubmit={handleSubmit}>
                                    <h5>Are You Ready For Membership?</h5>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Enter Your Name *"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter Your Email *"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <select
                                                id="category"
                                                value={formData.category}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Category *</option>
                                                {vendorCat.map((cat) => (
                                                    <option key={cat._id} value={cat.category}>
                                                        {cat.category}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <select
                                                id="gender"
                                                value={formData.gender}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Gender *</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className=" col-md-6">
                                            <select
                                                id="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select City</option>
                                                {sortedCities.map((city, index) => (
                                                    <option key={index} value={city}>
                                                        {city}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                id="mobile"
                                                name="mobile"
                                                placeholder="Enter Your Mobile *"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <select
                                                id="payment"
                                                name="payment"
                                                value={formData.payment}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Payment Type *</option>
                                                <option value="free">Free</option>
                                                <option value="paid">Paid</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-12 text-right">
                                            <input className="btn-color rounded" type="submit" value="Send" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
