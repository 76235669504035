import axios from "axios";
import React, { useState } from "react";

function Setting() {
  const [freeVendorLimit, setFreeVendorLimit] = useState(0); // Default free vendor post limit
  const [paidVendorLimit, setPaidVendorLimit] = useState(0); // Default paid vendor post limit
  const [isSaving, setIsSaving] = useState(false); // Track saving state
  const [message, setMessage] = useState(""); // Display success or error message

  const handleSave = async () => {
    setIsSaving(true);
    setMessage(""); // Clear any previous messages

    const settings = {
      freeVendorLimit,
      paidVendorLimit,
    };

    try {
      const response = await axios.post("/api/save-settings", settings, {
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.status === 200) {
        setMessage("Settings saved successfully!");
        setFreeVendorLimit(0);
        setPaidVendorLimit(0)
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Failed to save settings.");
      }
    } catch (error) {
      console.error("Error saving settings:", error);
      setMessage("An error occurred while saving settings.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Vendor Post Settings</h2>
      <div style={styles.inputGroup}>
        <label htmlFor="freeLimit">Free Vendor Post Limit:</label>
        <input
          type="number"
          id="freeLimit"
          value={freeVendorLimit}
          onChange={(e) => setFreeVendorLimit(Number(e.target.value))}
          style={styles.input}
        />
      </div>
      <div style={styles.inputGroup}>
        <label htmlFor="paidLimit">Paid Vendor Post Limit:</label>
        <input
          type="number"
          id="paidLimit"
          value={paidVendorLimit}
          onChange={(e) => setPaidVendorLimit(Number(e.target.value))}
          style={styles.input}
        />
      </div>
      <button
        onClick={handleSave}
        style={{ ...styles.saveButton, opacity: isSaving ? 0.7 : 1 }}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : "Save Settings"}
      </button>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "400px",
    margin: "0 auto",
    textAlign: "center",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  inputGroup: {
    marginBottom: "20px",
    textAlign: "left",
  },
  input: {
    display: "block",
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    marginTop: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  saveButton: {
    backgroundColor: "#4caf50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "4px",
  },
  message: {
    marginTop: "15px",
    fontSize: "14px",
    color: "green",
  },
};

export default Setting;
