import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";


const AddCategory = () => {
    const [formData, setFormData] = useState({
        category: "",
        categoryIcon: null, // File object for the uploaded icon
    });

    const [previewImage, setPreviewImage] = useState(null); // Preview of uploaded image
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            setFormData((prev) => ({
                ...prev,
                categoryIcon: file,
            }));
            setPreviewImage(URL.createObjectURL(file)); // Create a preview URL
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.category || !formData.categoryIcon) {
            Swal.fire("Error", "Please fill in all fields.", "error");
            return;
        }

        const form = new FormData();
        form.append("category", formData.category);
        form.append("categoryIcon", formData.categoryIcon);

        try {
            const response = await axios.post(
                `/api/add-category`,
                form,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            Swal.fire("Success", "Category added successfully!", "success");

            // Reset the form and preview image
            setFormData({
                category: "",
                categoryIcon: null,
            });
            setPreviewImage(null);

            // Navigate to the dashboard or reload category data
            navigate("/dashboard/total-category");
        } catch (error) {
            console.error("Error adding category:", error.response?.data || error.message);
            Swal.fire("Error", "Failed to add category.", "error");
        }
    };

    return (
        <main className="content px-3 py-2">
            <div className="container-fluid">
                <div className="title-bar">
                    <h4>Add New Category</h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="category" className="form-label">Category Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="category"
                                    name="category"
                                    value={formData.category}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="categoryIcon" className="form-label">Category Icon</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="categoryIcon"
                                    name="categoryIcon"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    required
                                />
                                {/* Preview uploaded image */}
                                {previewImage && (
                                    <div className="mt-3">
                                        <img
                                            src={previewImage}
                                            alt="Preview"
                                            style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                            </div>
                            <button type="submit" className="btn btn-success">Add Category</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddCategory;
