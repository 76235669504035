import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import khiladiindia from "../assets/khiladilogo.png";
import whatsappIcon from "../assets/whatsappIcon.png";
import callIcon from "../assets/callIcon.png";

function VendorCard({ data, sportId }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (data.isPaid === "Paid") {
      const categoryName = sportId; // Use the category name from the prop
      const vendorTitle = data.title; // Use vendor title as the URL parameter
      const formattedTitle = vendorTitle; // Replace spaces with hyphens
      console.log(formattedTitle)
      navigate(`/${categoryName}/${formattedTitle}`);
    }
  };


  const handleGetDirections = (event) => {
    event.stopPropagation();
    window.location.href = data.mapLocation;
  };

  const handleCallClick = (event) => {
    event.stopPropagation();
  };

  const handleWhatsAppClick = (event) => {
    event.stopPropagation();
  };

  return (

    <div
      onClick={handleCardClick}
      className="vendor-card card mb-4 shadow-sm"
      style={{
        border: data.isPaid === "Paid" ? "1px solid #2991c8" : "1px solid #e83b3b",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
        cursor: data.isPaid === "Paid" ? "pointer" : "default",
      }}
    >
      <div className="row g-0 align-items-center">
        <div className="col-3 text-center">
          {data.profileUrl ? (
            <img className="rounded-circle text-black"
              src={`https://api.khiladiindia.com/backend/uploads/profileImage/${data.profileUrl.split('/').pop()}`}
              alt={data.title}
              style={{ objectFit: 'cover',maxHeight: "150px",minHeight:"120px",minWidth:"120px", maxWidth: "150px" }}
            />
          ) : (
            "NA"
          )}
        </div>

        <div className="col-6">
          <div className="card-body p-2" style={{ textAlign: "left" }}>
            <h5 className="card-title text-dark mb-2" style={{ fontSize: "18px", fontWeight: "500" }}>
              {data.title}
            </h5>
            <p className="text-muted small mb-3">📍 {data.address}</p>
            <div className="d-flex gap-2 align-items-center">
              {data.isPaid === "Paid" ? (
                <>
                  <a
                    href={`tel:${data.phone}`}
                    onClick={handleCallClick}
                    className="btn btn-light btn-sm d-flex align-items-center gap-2"
                  >
                    <img
                      src={callIcon}
                      alt="Call"
                      className="img-fluid"
                      style={{ maxWidth: "20px" }}
                    />
                    <span className="d-none d-sm-inline">Call</span>
                  </a>
                  <a
                    href={`https://wa.me/${data.phone}`}
                    onClick={handleWhatsAppClick}
                    className="btn btn-success btn-sm d-flex align-items-center gap-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={whatsappIcon}
                      alt="WhatsApp"
                      className="img-fluid"
                      style={{ maxWidth: "20px" }}
                    />
                    <span className="d-none d-sm-inline">WhatsApp</span>
                  </a>
                </>
              ) : (
                <span className="badge bg-danger text-white fw-bold">
                  {data.category?.category || "Category not found"}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-3 text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={handleGetDirections}
            className="mt-3"
          >
            Get Directions
          </Button>
        </div>
      </div>
    </div>
  );
}


export default VendorCard;
