import React, { useEffect, useState } from "react";
import axios from "axios";
import callIcon from "../../../../assets/images/call.png"; // Replace with actual call icon path
import { Button } from "react-bootstrap";

const TrainerListing = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // Filter state variables
    const [experienceFilter, setExperienceFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");

    // Fetch trainers on component mount
    useEffect(() => {
        setLoading(true); // Set loading to true at the start
        axios
            .get("/api/trainer")
            .then((response) => {
                console.log(response.data); // Debugging response structure
                setJobs(response.data); // Set trainers in state
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch((error) => {
                setError("Failed to fetch trainers. Please try again later.");
                setLoading(false); // Set loading to false even on error
            });
    }, []);

    // Extract unique categories and experience levels from the jobs data
    const uniqueCategories = [...new Set(jobs.map((job) => job.category))];
    const uniqueExperience = [...new Set(jobs.map((job) => job.experience))];

    // Filter jobs based on the selected filters
    const filteredJobs = jobs.filter((job) => {
        const matchesExperience =
            experienceFilter === "" || job.experience === experienceFilter;
        const matchesCategory =
            categoryFilter === "" || job.category === categoryFilter;
        const matchesLocation =
            locationFilter === "" || job.address.toLowerCase().includes(locationFilter.toLowerCase());
        return matchesExperience && matchesCategory && matchesLocation;
    });

    if (loading) return <p className="loading">Loading job posts...</p>;
    if (error) return <p className="error">{error}</p>;

    const handleGetDirections = (mapLocation) => {
        if (mapLocation) {
            window.location.href = mapLocation;
        } else {
            alert("Map location is not available for this trainer.");
        }
    };

    return (
        <div className="jobsListing-container position-relative">
            <div className="position-relative col-md-3">
            <div className="position-relative h-100">
            <div className="filter-container position-sticky">
                <label>
                    Experience:
                    <select
                        value={experienceFilter}
                        onChange={(e) => setExperienceFilter(e.target.value)}
                    >
                        <option value="">All</option>
                        {uniqueExperience.map((exp) => (
                            <option key={exp} value={exp}>
                                {exp}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    Category:
                    <select
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                    >
                        <option value="">All</option>
                        {uniqueCategories.map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    Location:
                    <input
                        type="text"
                        placeholder="Enter city"
                        value={locationFilter}
                        onChange={(e) => setLocationFilter(e.target.value)}
                    />
                </label>
            </div>
            </div>
            </div>
            {/* Job Cards Section */}
            <div className="jobCards-container">
                {filteredJobs.length > 0 ? (
                    filteredJobs.map((job) => (
                        <div key={job._id} className="jobCard">
                            {job.city && <span className="jobCard-badge">★ Top Match</span>}
                            <div className="d-flex row">
                                <div className="jobCard-header mt-3 col-md-6">
                                    <div className="jobCard-body">
                                        <div className="jobCard-details">
                                            <h2 className="jobCard-title">{job.phone}</h2>
                                            <p className="jobCard-location">📍 {job.address}</p>
                                            <p className="jobCard-category">{job.category}</p>
                                            <div className="jobCard-tags">
                                                <span className="minExpReq">Experience:</span>
                                                <span className="jobCard-tag vacancy">{job.experience}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jobCard-footer col-md-6">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>{job.title}</h2>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleGetDirections(job.mapLocation)}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            Get Directions
                                        </Button>
                                    </div>
                                    <p className="jobCard-benefits">
                                        {job.benefits && job.benefits.length > 0
                                            ? `Benefits: ${job.benefits.join(", ")}`
                                            : ""}
                                    </p>

                                    <div className="jobCard-footer col-md-6" >
                                        <a href={`tel:${job.phone}`} className="" style={{ display: "flex", justifyContent: "space-between",marginBottom:"20px" }}>
                                            <img src={callIcon} alt="Call" />
                                        </a>
                                    </div>
                                    <div className="jobCard-extra">
                                        <p className="jobCard-company">Address: {job.address}</p>
                                        <p className="jobCard-timestamp text-black">
                                            {/* {new Date(job.startDate).toLocaleDateString()} - {new Date(job.expireDate).toLocaleDateString()} */}
                                        </p>
                                    </div>
                                    {job._id && (
                                        <div className="jobCard-verified">
                                            <img
                                                src="https://img.icons8.com/fluency/15/null/verified-badge.png"
                                                alt="Verified"
                                                className="jobCard-verifiedIcon"
                                            />
                                            <span>Verified <b>Safe Job Hai</b></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-jobs">No jobs available for the selected filters.</p>
                )}
            </div>

            {/* Filter Section */}

        </div>
    );
};

export default TrainerListing;
