import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai'; // Import close icon
import khiladilogo from '../assets/khiladilogo.png';
import axios from 'axios';


export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState('');
  const [userData, setUserData] = useState(localStorage.getItem("user") || null);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const loginPostRequest = async (e) => {
    e.preventDefault();
    setIsError(""); 
    setIsSuccess(""); 

    try {
      const response = await axios.post(
        `/api/vendor_login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const user = response.data;
      if (user.msg === "Please activate your account!") {
        setIsError("Your account is not active. Please contact support.");
        return;
      }

      localStorage.setItem("user", JSON.stringify(user));
      setIsSuccess("Login successful!");
      setEmail("");
      setPassword("");
      navigate("/dashboard");

      setTimeout(() => {
        setIsSuccess("");
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data) {
        setIsError(error.response.data.msg || "Login failed. Please try again.");
      } else {
        setIsError("Something went wrong. Please try again.");
      }
    }
  };

  const handleClose = () => {
    navigate("/");
  };


  return (
    <div className="">
      <div className="modal1">
        <div className="login-modal mt-0" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content1">
            <div className="d-flex headerlogin">
              <div>
                <img src={khiladilogo} alt="Logo" className="logo-left" />
              </div>
              <div className="logotext">
                <h2 className="login-title">Welcome</h2>
                <p className="login-subtitle">Login for a seamless experience</p>
              </div>
              {/* Close button */}
              <button className="close-buttonLogin" onClick={handleClose}>
                <AiOutlineClose size={20} />
              </button>
            </div>
            <form onSubmit={loginPostRequest}>
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Username*"
                  className="form-control font-weight-bold"
                  required
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password*"
                  className="form-control font-weight-bold"
                  required
                />
              </div>
              {isError && <p className="text-danger">{isError}</p>}
              {isSuccess && <p className="text-success">{isSuccess}</p>}
              <button type="submit" className="btn-login">Login</button>
              <div className="mt-3 text-center">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
