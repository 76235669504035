import axios from "axios";
import Swal from 'sweetalert2';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DUMMYCITIES from '../../../components/dummydata.json'

function UpdateJobForm() {

  const { id } = useParams(); // Get job ID from URL
  const navigate = useNavigate();

  const [jobTitle, setJobTitle] = useState("");
  const [numOpenings, setNumOpenings] = useState(1);
  const [jobType, setJobType] = useState("Full Time");
  const [workLocation, setWorkLocation] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [gender, setGender] = useState("Male");
  const [qualification, setQualification] = useState("Any");
  const [minExp, setMinExp] = useState("");
  const [maxExp, setMaxExp] = useState("");
  const [contractJob, setContractJob] = useState(false);
  const [freshersOnly, setFreshersOnly] = useState(false);
  const [interviewAddress, setInterviewAddress] = useState("");
  const [allowCalls, setAllowCalls] = useState(true);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [skills, setSkills] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [mobNumber, setMobNumber] = useState([]);
  const [EmailId, setEmailId] = useState([]);
  const [vendorCat, setVendorCat] = useState([]);
  const [jobTiming, setJobTiming] = useState({ start: "09:00", end: "18:00" });
  const [workingDays, setWorkingDays] = useState("");
  const [newSkill, setNewSkill] = useState("");
  const handleAddSkill = () => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([newSkill, ...skills]); // Add new skill to the top
      setNewSkill(""); // Reset input field after adding
    }
  };

  const toggleSelection2 = (skill) => {
    if (skills.includes(skill)) {
      setSkills(skills.filter((s) => s !== skill)); // Remove if already selected
    } else {
      setSkills([skill, ...skills]); // Add skill to the top if not selected
    }
  };

  const benefitsOptions = ["Cab", "Meal", "Insurance", "PF", "Medical Benefits"];
  const depositeConfirmOptions = ["Yes", "No"];
  const documentsOptions = ["PAN Card", "Aadhar Card", "Bank Account", "Academic Certificate"];
  const workingDaysOptions = ["5 days working", "6 days working", "Other"];
  const toggleSelection = (option, list, setList) => {
    if (list.includes(option)) {
      setList(list.filter((item) => item !== option));
    } else {
      setList([...list, option]);
    }
  };


  const sortedCities = Object.values(DUMMYCITIES)
  .flat()
  .map((city) => city.trim())
  .sort((a, b) => a.localeCompare(b));


  useEffect(() => {
    if (id) {
      axios.get(`/api/get-job/${id}`)
        .then((response) => {
          const data = response.data.data;
          setJobTitle(data.jobTitle);
          setMobNumber(data.mobNumber);
          setEmailId(data.EmailId);
          setNumOpenings(data.numOpenings);
          setJobType(data.jobType);
          setWorkLocation(data.workLocation);
          setCity(data.city);
          setLocality(data.locality);
          setGender(data.gender);
          setQualification(data.qualification);
          setMinExp(data.minExp);
          setMaxExp(data.maxExp);
          setMinSalary(data.minSalary);
          setMaxSalary(data.maxSalary);
          setSkills(data.skills);
          setBenefits(data.benefits);
          setJobTiming(data.jobTiming);
          setWorkingDays(data.workingDays);
          setInterviewAddress(data.interviewAddress);
          setAllowCalls(data.allowCalls);
        })
        .catch((error) => {
          console.error("Error fetching job data:", error);
        });
    }
  }, [id]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedJobData = {
      jobTitle,
      mobNumber,
      EmailId: EmailId,
      numOpenings,
      jobType,
      workLocation,
      city,
      locality,
      gender,
      qualification,
      minExp,
      maxExp,
      minSalary,
      maxSalary,
      skills,
      benefits,
      jobTiming,
      workingDays,
      interviewAddress,
      allowCalls,
    };

    axios.put(`/api/update-job/${id}`, updatedJobData)
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Job details updated successfully.",
          icon: "success",
        });
        navigate("/dashboard/total-jobs"); // Redirect to the job list
      })
      .catch((error) => {
        console.error("Error updating job:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to update job details.",
          icon: "error",
        });
      });
  };

  // Handle closing the form
  const handleCloseForm = () => {
    navigate("/dashboard/job-approval"); // Redirect to the job list
  };


  const selectedStyle = {
    backgroundColor: "#d66e6e",
    color: "white !important",
    borderColor: "#d66e6e",
  };

  const unselectedStyle = {
    // backgroundColor: "transparent",
    color: "#000 !important",
    borderColor: "#ccc",
  };




  return (
    <div className="job-post-form jobformmain container">
      <div className="form-headingh5 row">
        <h5 className="form-heading col-md-12 text-center">Update Job Details</h5>
      </div>
      <form onSubmit={handleSubmit} className="joFormMain">
        {/* Prepopulate each field with the current job data */}
        <div className="mb-3 w-50">
          <label className="form-label">Job Title *</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Title of job"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            required
          />
        </div>

        <div className="mb-3 w-50">
          <label className="form-label">Mobile Number *</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Your Number"
            value={mobNumber}
            onChange={(e) => setMobNumber(e.target.value)}
            maxLength="10"
            pattern="\d{10}"
            required
          />
        </div>

        <div className="mb-3 w-50">
          <label className="form-label">Email Id *</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Your Email"
            value={EmailId}
            onChange={(e) => setEmailId(e.target.value)}
            min="1"
            required
          />
        </div>

        {/* Number of Openings */}
        <div className="mb-3 w-25">
          <label className="form-label">Number of Openings *</label>
          <input
            type="text"
            className="form-control"
            placeholder="e.g., 1"
            value={numOpenings}
            onChange={(e) => setNumOpenings(e.target.value)}
            min="1"
            required
          />
        </div>

        {/* Job Type */}
        <div className="mb-3">
          <label className="form-label">Job Type *</label>
          <div>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={jobType === "Full Time" ? selectedStyle : unselectedStyle}
              onClick={() => setJobType("Full Time")}
            >
              Full Time
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={jobType === "Part Time" ? selectedStyle : unselectedStyle}
              onClick={() => setJobType("Part Time")}
            >
              Part Time
            </button>
          </div>
        </div>

        {/* Work Location Type */}
        <div className="mb-3">
          <label className="form-label">Work Location Type *</label>
          <div>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={workLocation === "Work from home" ? selectedStyle : unselectedStyle}
              onClick={() => setWorkLocation("Work from home")}
            >
              Work from home
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={workLocation === "Work from office" ? selectedStyle : unselectedStyle}
              onClick={() => setWorkLocation("Work from office")}
            >
              Work from office
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={workLocation === "Field job" ? selectedStyle : unselectedStyle}
              onClick={() => setWorkLocation("Field job")}
            >
              Field job
            </button>
          </div>
        </div>

        {/* Choose City */}
        <div className="mb-3 row">
          <div className="col-md-3">
            <label className="form-label">Choose City *</label>
            <select
              className="form-control"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            >
              <option value="">Select a city</option> {/* Default placeholder */}
              {sortedCities.map((cityOption) => (
                <option key={cityOption} value={cityOption}>
                  {cityOption}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label className="form-label">Job Locality *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Your Local Address"
              value={locality}
              onChange={(e) => setLocality(e.target.value)}
              required
            />
          </div>
        </div>
        {/* Gender */}
        <div className="mb-3">
          <label className="form-label">Gender *</label>
          <div>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={gender === "Male" ? selectedStyle : unselectedStyle}
              onClick={() => setGender("Male")}
            >
              Male
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={gender === "Female" ? selectedStyle : unselectedStyle}
              onClick={() => setGender("Female")}
            >
              Female
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={gender === "Other" ? selectedStyle : unselectedStyle}
              onClick={() => setGender("Any")}
            >
              Other
            </button>
          </div>
        </div>
        {/* Minimum Qualification Required */}

        <div className="mb-3">
          <label className="form-label">Minimum Qualification Required *</label>
          <div>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={qualification === "Any" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("Any")}
            >
              Any
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={qualification === "10th" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("10th")}
            >
              10th
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={qualification === "12th" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("12th")}
            >
              12th
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={qualification === "Diploma" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("Diploma")}
            >
              Diploma
            </button>
            <button
              type="button"
              className="btn me-2 selectjobpost"
              style={qualification === "Graduate" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("Graduate")}
            >
              Graduate
            </button>
            <button
              type="button"
              className="btn selectjobpost"
              style={qualification === "Post Graduate" ? selectedStyle : unselectedStyle}
              onClick={() => setQualification("Post Graduate")}
            >
              Post Graduate
            </button>
          </div>
        </div>

        {/* Required Experience */}
        <div className="mb-3">
          <label className="form-label">Required Experience *</label>
          <div className="row">
            {/* Min Experience Dropdown */}
            <div className="col-md-3">
              <select
                className="form-select minExpJob"
                value={minExp}
                onChange={(e) => setMinExp(e.target.value)}
                required
              >
                <option value="" disabled>
                  Min exp.
                </option>
                <option value="0 month">0 month</option>
                <option value="6 months">6 months</option>
                <option value="1 year">1 year</option>
                <option value="2 years">2 years</option>
                <option value="3 years">3 years</option>
                <option value="4 years">4 years</option>
                <option value="5 years">5 years</option>
                <option value="6+ years">6+ years</option>
              </select>
            </div>
            <div className="col-md-1"><p className="text-center">to</p></div>
            {/* Max Experience Dropdown */}
            <div className="col-md-3">
              <select
                className="form-select maxExpJob"
                value={maxExp}
                onChange={(e) => setMaxExp(e.target.value)}
                required
              >
                <option value="" disabled>
                  Max exp.
                </option>
                <option value="1 year">1 year</option>
                <option value="2 years">2 years</option>
                <option value="3 years">3 years</option>
                <option value="4 years">4 years</option>
                <option value="5 years">5 years</option>
                <option value="6 years">6 years</option>
                <option value="6+ years">6+ years</option>
              </select>
            </div>
          </div>

        </div>

        <div className="mb-3">
          <label className="form-label">Salary Details (Monthly) *</label>
          <div className="row">
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                placeholder="Min Salary"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
              />
            </div>
            <div className="col-md-1"><p className="text-center">to</p></div>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                placeholder="Max Salary"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Job Benefits (Optional)</label>
          <div>
            {benefitsOptions.map((benefit) => (
              <button
                key={benefit}
                type="button"
                className="btn me-2 selectjobpost"
                style={benefits.includes(benefit) ? selectedStyle : unselectedStyle}
                onClick={() => toggleSelection(benefit, benefits, setBenefits)}
              >
                {benefit}
              </button>
            ))}
          </div>
        </div>


        <div className="mb-3 w-50">
          <label className="form-label">Job Skills (Optional)</label>
          <div>
            {/* Display existing skills as buttons */}
            {skills.map((skill) => (
              <button
                key={skill}
                type="button"
                className={`btn me-2 ${skills.includes(skill) ? selectedStyle : unselectedStyle}`}
                onClick={() => toggleSelection2(skill)}
              >
                {skill}
              </button>
            ))}

            {/* Input field for adding a new skill */}
            <input
              type="text"
              className="form-control mt-2"
              value={newSkill}
              onChange={(e) => setNewSkill(e.target.value)}
              placeholder="Add a new skill"
            />

            {/* Button to add the new skill */}
            <button
              type="button"
              className="btn btn-primary mt-2 selectskilss"
              onClick={handleAddSkill}
            >
              Add Skill
            </button>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Documents Required for this Job (Optional)</label>
          <div>
            {documentsOptions.map((document) => (
              <button
                key={document}
                type="button"
                className={`btn me-2 selectjobpost ${documents.includes(document) ? selectedStyle : unselectedStyle}`}
                onClick={() => toggleSelection(document, documents, setDocuments)}
              >
                {document}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Job Timings *</label>
          <div className="row">
            <div className="col-md-3">
              <input
                type="time"
                className="form-control"
                value={jobTiming.start}
                onChange={(e) => setJobTiming({ ...jobTiming, start: e.target.value })}
              />
            </div>
            <div className="col-md-1"><p className="text-center">to</p></div>
            <div className="col-md-3">
              <input
                type="time"
                className="form-control"
                value={jobTiming.end}
                onChange={(e) => setJobTiming({ ...jobTiming, end: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Working Days *</label>
          <div>
            {workingDaysOptions.map((day) => (
              <button
                key={day}
                type="button"
                className={`btn me-2 selectjobpost ${workingDays === day ? selectedStyle : unselectedStyle}`}
                onClick={() => setWorkingDays(day)}
              >
                {day}
              </button>
            ))}
          </div>
        </div>

        {/* Full Interview Address */}
        <div className="mb-3 w-50">
          <label className="form-label">Full Interview Address *</label>
          <textarea
            // type="text"
            className="form-control"
            cols="3"
            rows="3"
            value={interviewAddress}
            onChange={(e) => setInterviewAddress(e.target.value)}
            required
          />
          {/* <small className="form-text text-muted">
            <a href="#edit" className="text-decoration-none">Edit</a>
          </small> */}
        </div>

        {/* Communication Preferences */}
        <div className="mb-3">
          <label className="form-label">Communication Preferences</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={allowCalls}
              onChange={(e) => setAllowCalls(e.target.checked)}
            />
            <label className="form-check-label">
              Allow candidates to call between 10:00 am - 07:00 pm
            </label>
          </div>

        </div>
        <hr></hr>

        <div className="jobpostbuttonupper">
          <button type="submit" className="btn btn-primary text-white">
            Update Job
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateJobForm;
