import React, { useState } from "react";
import "../Styles.css";
import cardImage from "../assets/card_img.png";
import TrainerPaymentForm from "../pages/TrainerPaymentForm";
import { Link } from "react-router-dom";

const trainerPaymentForm = () => {

  // const handleRegisterClick = () => {
  //   setIsFormVisible(true);
  //   document.body.style.overflow = "hidden"; // Prevent background scrolling
  // };


  return (
    <div className="checkout-container">
      {/* Banner Section */}
      <div className="checkout-banner">
        <div className="checkout-banner-content">
          <h1 >Become a Certified Trainer</h1>
          <p>
            Join our community of skilled trainers. Register now and take the
            first step towards your journey as a professional sports trainer.
          </p>
          <Link to="/trainer-details"
            className="checkout-banner-button"
            // onClick={handleRegisterClick}
          >
            Register Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default trainerPaymentForm;
